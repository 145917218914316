import { type FC, useEffect, useState } from 'react';
import styles from './FilterPanel.module.scss';
import GeneralDropdownSelect from 'components/shared/forms/GeneralDropdownSelect';
import CircleList from 'components/shared/CircleList';
import MembersSearchInput from 'components/shared/forms/SearchForm/MembersSearchInput';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { filterMembersSelector, listMembersSelector } from 'features/members/membersSlice';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import { CircleControlStyle } from 'components/shared/forms/GeneralDropdownSelect/CustomStyles/CircleControlStyle';
import { CustomCheckboxOption } from 'components/shared/forms/GeneralDropdownSelect/CustomOptions/CheckBoxOption/CheckBoxOption';
import { type IListItem } from 'components/shared/CircleList/CircleList';
import {
  filterMembersFilterPanelSelector,
  listMembersFilterPanelSelector,
  listSelectedMembersForCirclesFilterPanelSelector,
  setFilteredMembers,
  setFilteredMembersPageFilter
} from 'features/membersFilterPanelList/membersFilterPanelListSlice';
import Preloader from 'components/shared/Preloader';
import { getFilteredPanelMembers } from 'features/membersFilterPanelList/membersFilterPanelListActions';
import { getMembers } from 'features/members/membersActions';

import MenuDrawer from 'components/shared/MenuDrawer';
import Button from 'components/shared/Button';
import { useSearchParams } from 'react-router-dom';
import { getProjectsFilterList } from 'features/projectsFilterList/projectsFilterListActions';
import { getClientsFilterList } from 'features/clientsFilterList/clientsFilterListActions';
import ProjectsFilter from './ProjectsFilter';
import { projectsFilterListSelector } from 'features/projectsFilterList/projectsFilterListSlice';



const FilterPanel: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const members = useSelector(listMembersSelector);
  const membersPageFilter = useSelector(filterMembersSelector);
  const membersFiltredPanelFilter = useSelector(filterMembersFilterPanelSelector);
  const selectedMembersFiltredPanelList = useSelector(listSelectedMembersForCirclesFilterPanelSelector);
  const isLoadingMembers = false;
  const filtredMembers = useSelector(listMembersFilterPanelSelector);
  const projects = useSelector(projectsFilterListSelector);
  const projectsIds = searchParams.getAll("projectsIds").map(p => Number(p));
  const clientsIds = searchParams.getAll("clientsIds").map(c => Number(c));
  const [listCircleItems, setListCircleItems] = useState<IListItem[]>([]);
  const [membersOptions, setMembersOptions] = useState<SelectOption[]>([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const selectAllOption: ISelectOption = {
    label: "All",
    value: "SelectAll",
  };
  const emptyMessage = 'No members!';

  useEffect(() => {
    dispatch(getMembers(membersPageFilter));
    dispatch(getProjectsFilterList());
    dispatch(getClientsFilterList());
    setSelectAllChecked(true);

    return () => {
      dispatch(
        setFilteredMembersPageFilter({
          ...membersFiltredPanelFilter,
          searchValue: '',
          membersIds: [],
          projectIds: [],
          clientsIds: [],
          isUpdateCircles: true,
        })
      );
    }
    
  }, []);

  useEffect(() => {
    dispatch(getFilteredPanelMembers(membersFiltredPanelFilter));
  }, [membersFiltredPanelFilter]);

  // Set up options for dropdown
  useEffect(() => {
    if (members && members.length > 0) {
      setMembersOptions([selectAllOption, ...members.map((member) => ({
        label: member.fullName,
        value: String(member.id),
      }))]);
    }
  }, [members]);

  useEffect(() => {
    // Set up all circles
    const updatedMembers = Object.values(selectedMembersFiltredPanelList).map(member => ({
      ...member,
      isChecked: filtredMembers[member.member.id]?.isChecked || false
    }));

    setListCircleItems(updatedMembers.map(state => ({
      id: state.member.id,
      label: state.member.fullName,
      isSelected: state.isChecked,
      data: state,
    })));

    // If some circle selected
    if (Object.keys(filtredMembers).length != members.length) {
      setSelectAllChecked(false);
    }
  }, [filtredMembers]);

  useEffect(() => {
    dispatch(
      setFilteredMembersPageFilter({
        ...membersFiltredPanelFilter,
        projectIds: projectsIds,
        clientsIds: clientsIds,
        membersIds: [],
        isUpdateCircles: true,
      })
    );
  }, [searchParams]);

  const handleOptionChange = (option: ISelectOption) => {

    const selectedCheckBox = option.value;
    let updatedFilteredMembers = { ...filtredMembers };

    // If all option selected
    if (selectedCheckBox === selectAllOption.value) {
      // If no one is selected, you can't remove all option 
      const otherUsersSelected = Object.values(updatedFilteredMembers).some((memberState) => memberState.isChecked);

      if (!otherUsersSelected && selectAllChecked) {
        return;
      }

      setSelectAllChecked(!selectAllChecked);

      if (!selectAllChecked) {
        dispatch(
          setFilteredMembersPageFilter({
            ...membersFiltredPanelFilter,
            searchValue: '',
            membersIds: [],
            projectIds: [],
            clientsIds: [],
            isUpdateCircles: true,
          })
        );
      } else {
        dispatch(setFilteredMembers({}));
      }
      return;
    }

    if (selectAllChecked) {
      setSelectAllChecked(false);
      updatedFilteredMembers = {};
    }

    const existingMemberIndex = Object.keys(updatedFilteredMembers).findIndex(key => key === selectedCheckBox);

    if (existingMemberIndex !== -1) {
      delete updatedFilteredMembers[selectedCheckBox];
      // If there was a last one, then set up all members
      if (Object.keys(updatedFilteredMembers).length == 0) {

        setSelectAllChecked(!selectAllChecked);

        updatedFilteredMembers = Object.fromEntries(
          members.map(member => [member.id.toString(), { member, isChecked: false }])
        );
      }
    } else {
      const memberToAdd = members.find(member => member.id.toString() === selectedCheckBox);
      if (memberToAdd) {
        updatedFilteredMembers[selectedCheckBox] = {
          member: memberToAdd,
          isChecked: true
        };
      }
    }

    dispatch(
      setFilteredMembersPageFilter({
        ...membersFiltredPanelFilter,
        membersIds: [...Object.values(updatedFilteredMembers).map(item => item.member.id)],
      })
    );
  };

  const UpdateItemsFromCircleList = (filteredItemsFromCircle: IListItem[]) => {

    const AllOption = filteredItemsFromCircle.length === members.length;
    setSelectAllChecked(AllOption);

    dispatch(
      setFilteredMembersPageFilter({
        ...membersFiltredPanelFilter,
        membersIds: [...filteredItemsFromCircle.map(item => item.id)],
      })
    );
  }

  return isLoadingMembers ? (
    <Preloader />
  ) : (
    <>
      <div className={styles.filterPanel}>
        <MembersSearchInput
          filter={membersFiltredPanelFilter}
          onFilterChange={getFilteredPanelMembers}
        />
        <div className={styles.circleListConteiner}>
          <label>Members:</label>
          {listCircleItems.length === 0 ? (
            <div className={styles.emptyMessage}>{emptyMessage}</div>
          ) : (
            <>
              <CircleList
                realItems={listCircleItems}
                updateItems={(filteredItemsFromCircle) => UpdateItemsFromCircleList(filteredItemsFromCircle)}
              />
              <GeneralDropdownSelect
                options={membersOptions}
                styles={CircleControlStyle}
                components={
                  {
                    Option: (props) =>
                      <CustomCheckboxOption {...props}
                        checkboxStates={{
                          [selectAllOption.value]: selectAllChecked,
                          ...Object.fromEntries(Object.values(filtredMembers).map(state => [state.member.id.toString(), state.isChecked]))
                        }}
                        handleOptionChange={(handleOptionChange)} />,
                    DropdownIndicator: null,
                  }
                }
                isSearchable={false}
                value={null}
                placeholder={`${Object.keys(selectedMembersFiltredPanelList).length}+`}
                isMulti={true}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => null}
                onBlur={() => setMenuIsOpen(false)}
              />
            </>
          )}
        </div>
        <div>
          <Button variant="primary" onClick={() => setFiltersOpen(true)}>
            Filters
          </Button>
        </div>
        <MenuDrawer open={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <ProjectsFilter
            projects={projects}
            />
        </MenuDrawer>
      </div>
      <ul>
        {Object.values(filtredMembers).map(state =>
          <li key={state.member.id}>
            {state.member.fullName}
          </li>)}
      </ul>
    </>
  );
};

export default FilterPanel;