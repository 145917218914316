import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useAppDispatch } from 'app/store';
import AddProjectForm from 'components/projects/AddProjectForm';
import SearchInput from 'components/shared/forms/SearchForm/SearchInput';
import PageTabs from 'components/shared/PageTabs';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { clientsFilterListSelector } from 'features/clientsFilterList/clientsFilterListSlice';
import { permissionsUserSelector } from 'features/user/userSlice';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import { getClientsFilterList } from 'features/clientsFilterList/clientsFilterListActions';
import { IAddingProjectData } from 'features/projectsList/projectsListTypes';
import {
  filterProjectsListSelector,
  setProjectsPageFilter,
} from 'features/projectsList/projectsListSlice';
import {
  addProject,
  getProjectsList,
} from 'features/projectsList/projectsListActions';
import { startsWithNoSpace } from 'helpers/regularExpressions';
import styles from './Projects.module.scss';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { getSubscription } from 'features/subscription/subscriptionActions';

const tabsData = [
  {
    path: `/projects`,
    name: 'Active Projects',
  },
];

const Projects: FC = () => {
  const [canAddProject, setCanAddProject] = useState<boolean>(false);
  const projectsPageFilter = useSelector(filterProjectsListSelector);
  const permissions = useSelector(permissionsUserSelector);
  const members = useSelector(membersFilterListSelector);
  const clients = useSelector(clientsFilterListSelector);
  const sub = useSelector(subscriptionInfoSelector);
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const [addProjectFormOpen, setAddProjectFormOpen] = useState(false);
  const openAddProjectForm = () => setAddProjectFormOpen(true);
  const closeAddProjectForm = () => setAddProjectFormOpen(false);

  const isViewClientsAvailable = permissions?.some(
    (el) => el.name === 'View Client'
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (startsWithNoSpace.test(text)) {
      dispatch(setProjectsPageFilter({ searchValue: text, skip: 0, take: 20 }));
    }
  };
  const clearInput = () => {
    dispatch(setProjectsPageFilter({ searchValue: '', skip: 0, take: 20 }));
  };
  const debouncedSearch = useCallback(
    debounce((searchString: string) => {
      dispatch(
        getProjectsList({ searchValue: searchString, skip: 0, take: 20 })
      );
    }, 500),
    []
  );
  const onSubmit = (data: IAddingProjectData) => {
    dispatch(addProject(data));
  };

  useEffect(() => {
    dispatch(getProjectsList(projectsPageFilter));
    dispatch(getMembersFilterList());
    !!isViewClientsAvailable && dispatch(getClientsFilterList());
  }, []);
  useEffect(() => {
    if (didMountRef.current) {
      debouncedSearch(projectsPageFilter.searchValue);
    }
    didMountRef.current = true;
  }, [projectsPageFilter.searchValue]);

  useEffect(() => {
    if (sub.info != null) {
      const isAddingProjectAvailable = permissions?.some(
        (el) => el.name === 'Add Project'
      );
      const canAdd = isAddingProjectAvailable && (sub.info.plan == "Premium" || sub.info.stats.projectsCount < 1);
      setCanAddProject(canAdd!);
    }
  }, [sub.info]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <PageTabs tabsData={tabsData} />
        <div className={styles.searchAndActionBtnWrapper}>
          <div className={styles.searchWrapper}>
            <SearchInput
              value={projectsPageFilter.searchValue}
              onChange={handleInputChange}
              clearInput={clearInput}
              placeholder="Search projects"
              outlined
            />
          </div>
          {!!canAddProject && (
            <div className={styles.btnWrapper}>
              <Button
                variant="primary"
                onClick={openAddProjectForm}
                aria-label="Open project adding form."
              >
                Add project
              </Button>
            </div>
          )}
        </div>
      </div>

      <Outlet />

      <Modal
        title="Add project"
        open={addProjectFormOpen}
        onClose={closeAddProjectForm}
      >
        <AddProjectForm
          organizationMembers={members}
          organizationClients={clients}
          onSubmit={onSubmit}
          closeAddProjectForm={closeAddProjectForm}
        />
      </Modal>
    </div>
  );
};

export default Projects;
