import styles from './AccrualBalance.module.scss';

interface AccrualBalanceProps {
  currentBalance: string,
  hoursRequested: string,
  remainingBalance: string,
  isHorizontalOrientation: boolean
}

export const AccrualBalance = ({currentBalance, hoursRequested, remainingBalance, isHorizontalOrientation}:AccrualBalanceProps) => {
  return (
    <div className={`${ isHorizontalOrientation ? styles.wrapper : ''}`}>
      <div className={styles.infoContainer}>
        <div className={styles.constTextWrapper}>Current balance</div>
        <div className={styles.textWrapper}>{currentBalance}</div>
      </div>
      
      <div className={styles.infoContainer}>
        <div className={styles.constTextWrapper}>Hours requested</div>
        <div className={styles.textWrapper}>{hoursRequested}</div>
      </div>
      
      <hr className={styles.line} />
    
      <div className={styles.infoContainer}>
        <div className={styles.constTextWrapper}>Remaining balance</div>
        <div className={styles.textWrapper}>{remainingBalance}</div>
      </div>
    </div>
  );
};

export default AccrualBalance;
