import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppDispatch } from 'app/store';
import {
  addNewClient,
  fetchOrganizationClientsList,
  removeClient,
} from 'api/clientsList';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IAddClientData,
  IClientShortInfo,
} from 'features/clientInfo/clientInfoTypes';
import { removeClientReducer } from './clientsListSlice';
import { IFetchClientsParams } from './clientsListTypes';
import { getSubscription } from 'features/subscription/subscriptionActions';

export const getClientsList = createAsyncThunk<
  { list: IClientShortInfo[]; count: number },
  IFetchClientsParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientsList/getClientsList', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationClientsList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getClientsListInfiniteScroll = createAsyncThunk<
  { list: IClientShortInfo[]; count: number },
  IFetchClientsParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientsList/getClientsListInfiniteScroll', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationClientsList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const addClient = createAsyncThunk<
  void,
  IAddClientData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientsList/addClient', async (data, thunkApi) => {
  try {
    const res = await addNewClient(data);
    if (res.status === 200) {
      thunkApi.dispatch(getClientsList());
      thunkApi.dispatch(getSubscription());
      toast.success('Client Added!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const deleteClient = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientsList/deleteClient', async (clientId, thunkApi) => {
  try {
    const res = await removeClient(clientId);
    if (res.status === 204) {
      thunkApi.dispatch(removeClientReducer(clientId));
      thunkApi.dispatch(getSubscription());
      toast.success('Client Deleted!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
