import { ChangeEvent, useCallback, useEffect, useRef, type FC, useState } from 'react';
import styles from './MembersSearchInput.module.scss';
import { useAppDispatch } from 'app/store';
import SearchInput from '../SearchInput';
import debounce from 'lodash.debounce';
import { IFetchMembersParams } from 'features/members/membersTypes';

interface MembersSearchInputProps {
  filter: IFetchMembersParams;
  onFilterChange: (filter: IFetchMembersParams) => any;
}

const MembersSearchInput: FC<MembersSearchInputProps> = ({
  filter,
  onFilterChange
}) => {
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setInputValue(text);
    dispatch(
      onFilterChange({
        ...filter,
        searchValue: text.trim(),
      })
    );
  };

  const debouncedMembersFiltration = useCallback(
    debounce((params: IFetchMembersParams) => {
      dispatch(onFilterChange(params));
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      clearInput();
    };
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      debouncedMembersFiltration(filter);
    }
    didMountRef.current = true;
  }, [filter.searchValue]);

  const clearInput = () => {
    setInputValue('');
    dispatch(
      onFilterChange({
        ...filter,
        searchValue: '',
      })
    );
  };

  return (
    <div className={styles.searchBox}>
      <SearchInput
        value={inputValue}
        onChange={handleInputChange}
        clearInput={clearInput}
        placeholder="Search members"
        outlined
      />
    </div>
  );
};

export default MembersSearchInput;
