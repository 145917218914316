import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import styles from './AnnualAccrualOption.module.scss';
import { AccrualOptionProps } from '../AccrualOptions/AccrualOptions';

export const AnnualAccrualOption = ({isFullWidth}:AccrualOptionProps) => {
  const wrapperStyle = isFullWidth ? styles.fullWidthWrapper : styles.wrapper;

  return (
    <div className={wrapperStyle}>
      <ValidationTextInput
        label="Maximum accrual amount"
        name="maxAccrualAmount"
        type="text"
        description = "hours per year"
      />
    </div>
  );
};

export default AnnualAccrualOption;
