import { Time } from './Types/Time';
import { MinutesCoutInHour } from 'constants/timeConstanst';
import { splitTimeData } from './splitTimeData';

export const minutesCountInTime = (
    timeData: string
  ): number => {
    const time: Time = splitTimeData(timeData);
    
    return time.hours * MinutesCoutInHour + time.minutes;
  };
  