import client from './axiosInstance';

export const fetchMembersFilterList = async (projectIds?: number[]) => {
  if (projectIds) {
    const searchParams = new URLSearchParams();
    projectIds.map(e => searchParams.append('projectIds', e.toString()));
    return await client.get(`/members/base/members`, {
      params: searchParams,
    });
  } else {
    return await client.get(`/members/base/members`);
  }
};
