import React, { FC, useEffect, useState } from 'react';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import styles from './Tracker.module.scss';
import { IProjectInfo } from 'features/projectInfo/projectInfoTypes';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import { SingleValue } from 'react-select';
import { useSelector } from 'react-redux';
import { projectsFilterListSelector } from 'features/projectsFilterList/projectsFilterListSlice';
import { useAppDispatch } from 'app/store';
import { getProjectsFilterList } from 'features/projectsFilterList/projectsFilterListActions';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { CloseIcon } from 'assets/icons/CloseIcon';
import {
  setTrackerActiveState,
  setTrackerCounterState,
  setTrackerIntervalState,
  setTrackerPositionState,
  setTrackerProjectState,
  setTrackerTotalState,
  setTrackerVisibleState,
  trackerActiveSelector,
  trackerCounterSelector,
  trackerIntervalSelector,
  trackerPositionSelector,
  trackerProjectSelector,
  trackerTotalSelector
} from 'features/tracker/trackerSlice';
import { addTimeEntry } from 'features/timeEntries/timeEntriesActions';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay } from 'date-fns';
import { getDailyCounter } from 'features/tracker/trackerActions';

interface TrackerProps { }

const Tracker: FC<TrackerProps> = () => {

  const dispatch = useAppDispatch();

  const total = useSelector(trackerTotalSelector);
  const selectedProject = useSelector(trackerProjectSelector);
  const isActive = useSelector(trackerActiveSelector);
  let currentInterval = useSelector(trackerIntervalSelector);
  let position = useSelector(trackerPositionSelector);
  const counter = useSelector(trackerCounterSelector);
  const user = useSelector(selectInfoUserSelector);

  if (window.innerWidth < 600) {
    position = { x: 0, y: 0 };
  }

  const projects = useSelector(projectsFilterListSelector);

  const projectsOptions = projects.map((c) => ({
    value: String(c.id),
    label: c.name,
  }));

  useEffect(() => {
    dispatch(getProjectsFilterList());
  }, []);

  useEffect(() => {
    if (user != null && user.id != null) {
      dispatch(getDailyCounter({
        id: user.id,
        timeZoneName: user.timeZone.name
      }));
    }
  }, [user]);

  useEffect(() => {
    if (isActive && !currentInterval) {
      let localTimer = 0;
      currentInterval = setInterval(() => {
        localTimer++;
        dispatch(setTrackerCounterState({ counter: localTimer }));
      }, 1000);
      dispatch(setTrackerIntervalState(currentInterval));
    } else if (!isActive && currentInterval) {
      clearInterval(currentInterval);
      dispatch(setTrackerIntervalState(null));
      dispatch(setTrackerCounterState({ counter: 0 }));
    }
  }, [isActive]);

  const handleProjectsOptionsChange = (
    option: SingleValue<SelectOption>
  ) => {
    trackTime();
    dispatch(setTrackerActiveState(false));
    dispatch(setTrackerProjectState(option as SingleValue<SelectOption>));
  };

  const handleToggle = () => {
    if (!selectedProject) return;
    const value = !isActive;
    dispatch(setTrackerActiveState(value));

    if (!value) {
      trackTime();
    }
  };

  const trackTime = () => {

    if (selectedProject == null || user == null || counter < 60) return;

    const now = Date.now();
    const endTime = new Date(now);
    const startTime = new Date(now - counter * 1000);

    dispatch(setTrackerTotalState({ counter: total! + counter }));
    dispatch(
      addTimeEntry({
        memberId: Number(user.id),
        projectId: Number(selectedProject.value),
        start: zonedTimeToUtc(startTime, user!.timeZone.name).toISOString(),
        end: zonedTimeToUtc(endTime, user!.timeZone.name).toISOString(),
        isBillable: true,
        note: "",
      })
    );
  }

  const handleClose = () => {
    dispatch(setTrackerVisibleState(false));
  };

  const savePosition = (e: DraggableEvent, data: DraggableData) => {
    dispatch(setTrackerPositionState({ x: data.x, y: data.y }));
  };

  const formatItem = (value: number) => {
    return value >= 10 ? value : `0${value}`;
  };

  const formatNumber = (value: number | null) => {
    if (!value) return "00:00:00";
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;
    return `${formatItem(hours)}:${formatItem(minutes)}:${formatItem(seconds)}`;
  };

  return (
    <Draggable onStop={savePosition} defaultPosition={position}>
      <div className={styles.wrapper}>

        <button className={styles.close} onClick={handleClose}>
          <CloseIcon />
        </button>

        <div className={`${styles.tracker} ${isActive ? styles.activeTracker : ""}`}>
          <div className={styles.pressContainer}>
            <div
              className={`${styles.press} ${isActive ? styles.pressActive : ""} ${selectedProject ? "" : styles.disabledButton}`}
              onClick={handleToggle}>
              {isActive ? "STOP" : "START"}
            </div>
            <label>{selectedProject != null ? selectedProject?.label : "-"}</label>
          </div>
          <div className={styles.counterContainer}>
            <div className={styles.counter}>{formatNumber(counter)}</div>
            <div className={styles.counterDay}>Today: {formatNumber(total)}</div>
          </div>
        </div>

        <div className={styles.options}>
          <DropdownSelect
            label="Project"
            value={selectedProject}
            options={projectsOptions}
            onChange={(value) => {
              handleProjectsOptionsChange(value);
            }}
            isSearchable
            blurInputOnSelect={false}
          />
        </div>

      </div>
    </Draggable>
  );
};

export default Tracker;
