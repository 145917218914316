import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OIDCSignInCallback from 'components/auth/OIDCSignInCallback';
import OIDCSignOutCallback from 'components/auth/OIDCSignOutCallback';
import AppLayout from 'components/appLayout';
import Preloader from 'components/shared/Preloader';
import Auth from 'components/auth/Auth';
import AdminAndManagerPageGuard from 'protectedRoutes/AdminAndManagerPageGuard';
import AuthProtectedRoute from 'protectedRoutes/AuthProtectedRoute';
//projects
import EditProjectGeneral from 'pages/Projects/EditProject/EditProjectGeneral';
import EditProjectMembers from 'pages/Projects/EditProject/EditProjectMembers';
import ActiveProjects from 'pages/Projects/ActiveProjects';
import EditProject from 'pages/Projects/EditProject';
import Projects from 'pages/Projects';
// todo's
import ToDo from 'pages/ToDo/ToDo';
import ActiveToDos from 'pages/ToDo/ActiveToDos/ActiveToDo'
//members
import EditMemberInfo from 'pages/Members/EditMemberInfo';
import ActiveMembers from 'pages/Members/ActiveMembers';
import EditProjects from 'pages/Members/EditMemberInfo/EditProjects';
import EditPayments from 'pages/Members/EditMemberInfo/EditPayments';
import EditAbout from 'pages/Members/EditMemberInfo/EditAbout';
import EditRoles from 'pages/Members/EditMemberInfo/EditRoles';
import Members from 'pages/Members';
//clients
import EditClientGeneral from 'pages/Clients/EditClient/EditClientGeneral';
import ActiveClients from 'pages/Clients/ActiveClients';
import EditClient from 'pages/Clients/EditClient';
import Clients from 'pages/Clients';
//policies
import EditPolicyGeneral from 'pages/TimeOff/TimeOffPolicies/EditPolicy/EditPolicyGeneral';
import EditPolicyMembers from 'pages/TimeOff/TimeOffPolicies/EditPolicy/EditPolicyMembers';
import TimeOffPolicies from 'pages/TimeOff/TimeOffPolicies';
import EditPolicy from 'pages/TimeOff/TimeOffPolicies/EditPolicy';
//holidays
import EditHolidayGeneral from 'pages/TimeOff/TimeOffHolidays/EditHoliday/EditHolidayGeneral';
import EditHolidayMembers from 'pages/TimeOff/TimeOffHolidays/EditHoliday/EditHolidayMembers';
import TimeOffHolidays from 'pages/TimeOff/TimeOffHolidays';
import EditHoliday from 'pages/TimeOff/TimeOffHolidays/EditHoliday';
//time off requests
import SubmittedTimeOffRequests from 'pages/TimeOff/TimeOffRequests/SubmittedTimeOffRequests';
import ApprovedTimeOffRequests from 'pages/TimeOff/TimeOffRequests/ApprovedTimeOffRequests';
import DeniedTimeOffRequests from 'pages/TimeOff/TimeOffRequests/DeniedTimeOffRequests';
import EditTimeOffRequest from 'pages/TimeOff/TimeOffRequests/EditTimeOffRequest';
import AllTimeOffRequests from 'pages/TimeOff/TimeOffRequests/AllTimeOffRequests';
import TimeOffRequests from 'pages/TimeOff/TimeOffRequests';
//reports
import TimeAndActivity from 'pages/Reports/TimeAndActivity';
import AmountsOwed from 'pages/Reports/AmountsOwed';
import AllReports from 'pages/Reports/AllReports';
//general
import Organization from 'pages/Organization';
import Forbidden403 from 'pages/Forbidden403';
import NotFound404 from 'pages/NotFound404';
import Timesheets from 'pages/Timesheets';
import StartPage from 'pages/StartPage';
import Payments from 'pages/Payments';
import Account from 'pages/Account';

import * as Sentry from '@sentry/react';
import Invites from '../pages/Members/Invites';
import Subscription from 'pages/Subscription';
import Subscribe from 'pages/Subscribe';
import TimeOffReport from 'pages/Reports/TimeOffReport';
import TeamOverview from 'pages/TeamOverview';
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

class App extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Suspense fallback={<Preloader />}>
            <SentryRoutes>
              <Route path="/auth" element={<Auth />} />
              <Route path={'/signin-oidc'} element={<OIDCSignInCallback />} />
              <Route path={'/logout'} element={<OIDCSignOutCallback />} />
              <Route
                path="/"
                element={<AuthProtectedRoute element={<AppLayout />} />}
              >
                <Route
                  index
                  element={<AuthProtectedRoute element={<StartPage />} />}
                />
                <Route path="account" element={<Account />} />
                <Route
                  path="organization"
                  element={<AuthProtectedRoute element={<Organization />} />}
                >
                </Route>
                <Route
                  path="subscription"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AdminAndManagerPageGuard>
                          <Subscription />
                        </AdminAndManagerPageGuard>
                      }
                    />
                  }
                >
                </Route>
                <Route
                  path="members"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AdminAndManagerPageGuard>
                          <Members />
                        </AdminAndManagerPageGuard>
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={<AuthProtectedRoute element={<ActiveMembers />} />}
                  />
                  <Route
                    path="invites"
                    element={
                      <AuthProtectedRoute
                        element={
                          <AdminAndManagerPageGuard>
                            <Invites />
                          </AdminAndManagerPageGuard>
                        }
                      />
                    }
                  />
                </Route>
                <Route
                  path="members/:memberId/edit"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AdminAndManagerPageGuard>
                          <EditMemberInfo />
                        </AdminAndManagerPageGuard>
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={<AuthProtectedRoute element={<EditAbout />} />}
                  />
                  <Route
                    path="projects"
                    element={<AuthProtectedRoute element={<EditProjects />} />}
                  />
                  <Route
                    path="roles"
                    element={<AuthProtectedRoute element={<EditRoles />} />}
                  />
                  <Route
                    path="payments"
                    element={<AuthProtectedRoute element={<EditPayments />} />}
                  />
                </Route>
                <Route
                  path="projects"
                  element={<AuthProtectedRoute element={<Projects />} />}
                >
                  <Route
                    index
                    element={
                      <AuthProtectedRoute element={<ActiveProjects />} />
                    }
                  />
                </Route>
                <Route
                  path="projects/:projectId/edit"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AuthProtectedRoute
                          element={<EditProject />}
                        />
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <AuthProtectedRoute element={<EditProjectGeneral />} />
                    }
                  />
                  <Route
                    path="members"
                    element={
                      <AuthProtectedRoute element={<EditProjectMembers />} />
                    }
                  />
                </Route>
                <Route
                  path="clients"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AdminAndManagerPageGuard>
                          <Clients />
                        </AdminAndManagerPageGuard>
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={<AuthProtectedRoute element={<ActiveClients />} />}
                  />
                </Route>
                <Route
                  path="clients/:clientId/edit"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AuthProtectedRoute
                          element={
                            <AdminAndManagerPageGuard>
                              <EditClient />
                            </AdminAndManagerPageGuard>
                          }
                        />
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <AuthProtectedRoute element={<EditClientGeneral />} />
                    }
                  />
                </Route>
                <Route
                  path="payments"
                  element={<AuthProtectedRoute element={<Payments />} />}
                />
                <Route
                  path="time-entries"
                  element={<AuthProtectedRoute element={<Timesheets />} />}
                />
                <Route
                  path='todo'
                  element={<AuthProtectedRoute element={<ToDo />} />}>
                  <Route
                    index
                    element={
                      <AuthProtectedRoute element={<ActiveToDos />} />
                    }
                  />
                </Route>
                <Route
                  path="team-overview"
                  element={<AuthProtectedRoute element={<TeamOverview />}/>}
                />
                <Route
                  path="reports/time-and-activity"
                  element={<AuthProtectedRoute element={<TimeAndActivity />} />}
                />
                <Route
                  path="reports/time-and-activity/my"
                  element={<AuthProtectedRoute element={<TimeAndActivity />} />}
                />
                <Route
                  path="reports/amounts-owed"
                  element={<AuthProtectedRoute element={<AmountsOwed />} />}
                />
                <Route
                  path="reports/amounts-owed/my"
                  element={<AuthProtectedRoute element={<AmountsOwed />} />}
                />
                <Route
                  path="reports/time-off"
                  element={<AuthProtectedRoute element={<TimeOffReport />} />}
                />
                <Route
                  path="reports/time-off/my"
                  element={<AuthProtectedRoute element={<TimeOffReport />} />}
                />
                <Route
                  path="reports/all-reports"
                  element={<AuthProtectedRoute element={<AllReports />} />}
                />
                <Route
                  path="time-off/requests"
                  element={<AuthProtectedRoute element={<TimeOffRequests />} />}
                >
                  <Route
                    index
                    element={
                      <AuthProtectedRoute
                        element={<SubmittedTimeOffRequests />}
                      />
                    }
                  />
                  <Route
                    path="all"
                    element={
                      <AuthProtectedRoute element={<AllTimeOffRequests />} />
                    }
                  />
                  <Route
                    path="approved"
                    element={
                      <AuthProtectedRoute
                        element={<ApprovedTimeOffRequests />}
                      />
                    }
                  />
                  <Route
                    path="denied"
                    element={
                      <AuthProtectedRoute element={<DeniedTimeOffRequests />} />
                    }
                  />
                </Route>
                <Route
                  path="time-off/requests/:requestId/edit"
                  element={
                    <AuthProtectedRoute element={<EditTimeOffRequest />} />
                  }
                />
                <Route
                  path="time-off/policies"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AuthProtectedRoute
                          element={
                            <AuthProtectedRoute
                              element={
                                <AdminAndManagerPageGuard>
                                  <TimeOffPolicies />
                                </AdminAndManagerPageGuard>
                              }
                            />
                          }
                        />
                      }
                    />
                  }
                />
                <Route
                  path="time-off/policies/:policyId/edit"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AuthProtectedRoute
                          element={
                            <AuthProtectedRoute
                              element={
                                <AdminAndManagerPageGuard>
                                  <EditPolicy />
                                </AdminAndManagerPageGuard>
                              }
                            />
                          }
                        />
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <AuthProtectedRoute element={<EditPolicyGeneral />} />
                    }
                  />
                  <Route
                    path="members"
                    element={
                      <AuthProtectedRoute element={<EditPolicyMembers />} />
                    }
                  />
                </Route>
                <Route
                  path="time-off/holidays"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AuthProtectedRoute
                          element={
                            <AuthProtectedRoute
                              element={
                                <AdminAndManagerPageGuard>
                                  <TimeOffHolidays />
                                </AdminAndManagerPageGuard>
                              }
                            />
                          }
                        />
                      }
                    />
                  }
                />
                <Route
                  path="time-off/holidays/:holidayId/edit"
                  element={
                    <AuthProtectedRoute
                      element={
                        <AuthProtectedRoute
                          element={
                            <AuthProtectedRoute
                              element={
                                <AdminAndManagerPageGuard>
                                  <EditHoliday />
                                </AdminAndManagerPageGuard>
                              }
                            />
                          }
                        />
                      }
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <AuthProtectedRoute element={<EditHolidayGeneral />} />
                    }
                  />
                  <Route
                    path="members"
                    element={
                      <AuthProtectedRoute element={<EditHolidayMembers />} />
                    }
                  />
                </Route>
                <Route path="403forbidden" element={<Forbidden403 />} />
                <Route path="404notFound" element={<NotFound404 />} />
              </Route>
              <Route
                path="/"
                element={<AppLayout />}>
                <Route path="subscribe" element={<Subscribe />} />
              </Route>
              <Route path="*" element={<NotFound404 />} />
            </SentryRoutes>
          </Suspense>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
