import type { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { useField, FieldHookConfig } from 'formik';
import styles from './ValidationTextInput.module.scss';

interface ValidationTextInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  description?: string;
}

const ValidationTextInput: FC<
  ValidationTextInputProps & FieldHookConfig<string>
> = ({ label, description, ...props }) => {
  const [field, meta] = useField(props);
  
  const inputWrapperClassName = description
    ? styles.wrapperWithDescription
    : styles.wrapperWithoutDescription;
  
  const inputClassName = `form-control
    ${meta.touched && meta.error ? 'errorInput' : ''} 
    ${description ? styles.textInputWrapperWithDescription : ''}`;

  return (
    <div>
      {label && (
        <label className="input-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div className={inputWrapperClassName}>
        <input className={inputClassName}
          {...field}
          {...props}
          autoComplete="off"
        />
        {description && (
          <div className={styles.description}>{description}</div>
        )}
        {meta.touched && meta.error ? (
          <div className={styles.errorMessage}>*{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ValidationTextInput;
