import { setTimeInStringFormatIntoDate } from './setTimeInStringFormatIntoDate';
import {
  MillisecondCountInMinute
} from "constants/timeConstanst";

export const minutesCountInDateRangeWithTime = (
  startDate: string | Date,
  endDate: string | Date,
  startTime: string,
  endTime: string
): number => {
  const fullStartDate = new Date(setTimeInStringFormatIntoDate(startDate, startTime)).getTime();
  const fullEndDate = new Date(setTimeInStringFormatIntoDate(endDate, endTime)).getTime();
  const difference = fullEndDate - fullStartDate;

  return Math.ceil(difference / MillisecondCountInMinute);
 };