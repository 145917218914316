import {
  AmountsOwedReportsParamsForDownloading,
  IGetAmountsOwedReportsParams,
} from '../features/amountsOwedReports/amountsOwedReportsTypes';
import client from './axiosInstance';
import { createDownloadLinkFromPdf } from './timeAndActivityReports';

export const fetchAmountsOwedReports = async (
  params: IGetAmountsOwedReportsParams
) => {
  const {
    isMyReports,
    startDate,
    endDate,
    sumDateRanges,
    detailedReports,
    projectsIds,
    membersIds,
  } = params;
  const startDateQuery = startDate ? `?startDate=${startDate}` : '';
  const endDateQuery = endDate ? `&endDate=${endDate}` : '';

  const sumDateRangesQuery = sumDateRanges
    ? `&sumDateRanges=${sumDateRanges}`
    : '';

  const detailedReportsQuery = detailedReports
    ? `&detailedReports=${detailedReports}`
    : '';

  const projectsQuery =
    projectsIds && projectsIds.length > 0
      ? `&projectsIds=${projectsIds.join('&projectsIds=')}`
      : '';
  const membersQuery =
    membersIds && membersIds.length > 0
      ? `&membersIds=${membersIds.join('&membersIds=')}`
      : '';

  const queryStr = `${startDateQuery}${endDateQuery}${sumDateRangesQuery}${detailedReportsQuery}${projectsQuery}${membersQuery}`;

  if (isMyReports) {
    return await client.get(`/reports/amounts-owed/my${queryStr}`);
  } else {
    return await client.get(`/reports/amounts-owed${queryStr}`);
  }
};

export const downloadAmountsOwedReports = async (
  params: AmountsOwedReportsParamsForDownloading
) => {
  const {
    isMyReports,
    startDate,
    endDate,
    sumDateRanges,
    detailedReports,
    projectsIds,
    membersIds,
    organizationName,
    reportType,
  } = params;
  const startDateQuery = startDate ? `?startDate=${startDate}` : '';
  const endDateQuery = endDate ? `&endDate=${endDate}` : '';

  const sumDateRangesQuery = sumDateRanges
    ? `&sumDateRanges=${sumDateRanges}`
    : '';

  const detailedReportsQuery = detailedReports
    ? `&detailedReports=${detailedReports}`
    : '';

  const projectsQuery =
    projectsIds && projectsIds.length > 0
      ? `&projectsIds=${projectsIds.join('&projectsIds=')}`
      : '';
  const membersQuery =
    membersIds && membersIds.length > 0
      ? `&membersIds=${membersIds.join('&membersIds=')}`
      : '';

  const queryStr = `${startDateQuery}${endDateQuery}${sumDateRangesQuery}${detailedReportsQuery}${projectsQuery}${membersQuery}`;

  const pdf_url = `/reports/amounts-owed/pdf${isMyReports ? "/my" : ""}${queryStr}`;

  await client
    .get(pdf_url, {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'blob',
    })
    .then(
      createDownloadLinkFromPdf({
        startDate,
        endDate,
        reportType,
        organizationName,
      })
    )
    .catch((error: unknown) => {
      console.error('Error downloading file:', error);
    });
};
