import Select, { GroupBase, Props } from 'react-select';
import styles from './GeneralDropdownSelect.module.scss';
import { ISelectOption, DropdownSelectProps } from '../DropdownSelect/DropdownSelect';

export default function GeneralDropdownSelect<
  Option extends ISelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  error,
  errorMessage,
  withDescription,
  ...props
}: Props<Option, IsMulti, Group> & DropdownSelectProps) {

  return (
    <div>
      <Select {...props} />
      {error && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
}
