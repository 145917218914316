import { StylesConfig } from 'react-select';

export const CircleControlStyle: StylesConfig<any, any, any> = {
    
    control: (base, state) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      padding: 0,
      margin: 0,
      width: '36px',
      height: '36px',
      background: '#ccc',
      borderRadius: '50%', // setting border radius to 50% makes it a circle
      borderWidth: 2,
      borderStyle: 'solid', 
      borderColor: state.isFocused ? '#00B6FF' : '#FFFFFF',
      cursor: 'pointer',
      transition: 'transform 0.2s, z-index 0.2s',
      fontSize: 16,
      outline: 'none',
      zIndex: 1000,
      //remove unwanted border effects
      '&:hover': {
        transform: 'scale(1.1)',
      },
    }),
    menu: (base) => ({
      ...base,
      minWidth: '300px',
      width: '100%',
      background: '#fff',
      border: 'none',
      borderRadius: 4,
      boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
      zIndex: 1000,
    }),
    menuList: (base) => ({
      ...base,
      padding: '8px',
      background: '#FEFEFE',
      borderRadius: '4px',
      width: '100%',
      //remove unwanted border effects
      '&:hover': {},
    }),
    placeholder: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000000',
      fontSize: 15,
    }),
  };