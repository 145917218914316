import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import { ChangeMemberActivityStatusParams } from 'features/members/membersTypes';
import { changeMemberStatus } from 'features/members/membersActions';

interface ActiveMembersActionsListProps {
  memberId: number;
  isActive: boolean;
  canDisable: boolean;
  canRemove: boolean;
  handleRemove: (id: number) => void;
}

const ActiveMembersActionsList: FC<ActiveMembersActionsListProps> = ({
  memberId,
  isActive,
  canDisable,
  canRemove,
  handleRemove,
}) => {
  const dispatch = useAppDispatch();

  const handleMemberStatusChange = (
    params: ChangeMemberActivityStatusParams
  ) => {
    dispatch(changeMemberStatus(params));
  };

  const handleMemberRemove = () => {
    handleRemove(memberId);
  };

  return (
    <>
      <li>
        <Link to={`/members/${memberId}/edit`}>Edit member</Link>
      </li>
      {canDisable && (
        <li 
          onClick={() =>
            handleMemberStatusChange({ memberId, currentStatus: isActive })
          }
        >
          <button aria-label="Change status">
            {isActive ? 'Disable member' : 'Enable member'}
          </button>
        </li>
      )}
      {canRemove && (
        <li>
          <button onClick={handleMemberRemove} aria-label="Remove member.">
            Remove member
          </button>
        </li>
      )}
    </>
  );
};

export default ActiveMembersActionsList;
