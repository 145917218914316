import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import type { RootState } from 'app/store';
import type { IToDoEntity, IToDoInfoState } from './toDoInfoTypes';
import { getToDoByIdAction, updateToDoAction } from './toDoInfoActions';

const initialState : IToDoInfoState = {
    toDo: { } as unknown as IToDoEntity,
    isLoading: false,
    errorMessage: '',
}

export const toDoInfoSlice = createSlice({
    name: '@@toDo',
    initialState,
    reducers: {},
    extraReducers : (builder) => {
        builder.addCase(getToDoByIdAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getToDoByIdAction.fulfilled, (state, action) => {
            state.toDo = action.payload;
            state.isLoading = false;
        });
        builder.addCase(updateToDoAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateToDoAction.fulfilled, (state, action) => {
            state.toDo = action.payload;
            state.isLoading = false;
        });
        builder.addMatcher(isFulfilledAction, (state) => {
            state.errorMessage = '';
            state.isLoading = false;
        });
        builder.addMatcher(isRejectedAction, (state, action) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        });
    },
});

export const toDoInfoSelector = createSelector(
    [(state: RootState) => state.toDoInfo.toDo],
    (toDo) => toDo
);

export const isLoadingToDoInfoSelector = createSelector(
    [(state: RootState) => state.toDoInfo.isLoading],
    (isLoading) => isLoading
);