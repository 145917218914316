import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useAppDispatch } from 'app/store';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import AddTimeOffPolicyForm from 'components/timeOff/AddTimeOffPolicyForm';
import PoliciesTable from 'components/timeOff/PoliciesTable';
import SearchInput from 'components/shared/forms/SearchForm/SearchInput';
import Preloader from 'components/shared/Preloader';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import { IFetchClientsParams } from 'features/clientsList/clientsListTypes';
import {
  IAddingPolicyData,
  IFetchPoliciesParams,
} from 'features/policiesList/policiesListTypes';
import {
  addPolicy,
  getPoliciesList,
} from 'features/policiesList/policiesListActions';
import {
  filterPoliciesListSelector,
  loadingPoliciesListSelector,
  policiesListSelector,
  setPoliciesFilter,
} from 'features/policiesList/policiesListSlice';
import { startsWithNoSpace } from 'helpers/regularExpressions';
import styles from './TimeOffPolicies.module.scss';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { permissionsUserSelector } from 'features/user/userSlice';

const TimeOffPolicies: FC = () => {
  const policiesPageFilter = useSelector(filterPoliciesListSelector);
  const isLoading = useSelector(loadingPoliciesListSelector);
  const policiesData = useSelector(policiesListSelector);
  const permissions = useSelector(permissionsUserSelector);
  const [canAddPolicy, setCanAddPolicy] = useState<boolean>(false);
  const sub = useSelector(subscriptionInfoSelector);
  const didMountRef = useRef(false);
  const dispatch = useAppDispatch();
  const [addPolicyFormOpen, setAddPolicyFormOpen] = useState(false);
  const handleAddPolicy = (data: IAddingPolicyData) => {
    dispatch(addPolicy(data));
  };
  const emptyMessage = 'There are no created policies yet.';

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (startsWithNoSpace.test(text)) {
      dispatch(
        setPoliciesFilter({
          ...policiesPageFilter,
          searchValue: text,
          skip: 0,
          take: 20,
        })
      );
    }
  };

  const clearInput = () => {
    dispatch(
      setPoliciesFilter({
        ...policiesPageFilter,
        searchValue: '',
        skip: 0,
        take: 20,
      })
    );
  };
  const debouncedPoliciesFiltration = useCallback(
    debounce((params: IFetchPoliciesParams) => {
      dispatch(getPoliciesList(params));
    }, 300),
    []
  );

  useEffect(() => {
    dispatch(getMembersFilterList());
    dispatch(getPoliciesList(policiesPageFilter));
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      debouncedPoliciesFiltration(policiesPageFilter);
    }
    didMountRef.current = true;
  }, [policiesPageFilter.searchValue]);

  useEffect(() => {
    if (sub.info != null) {
      const isAddingPolicyAvailable = permissions?.some(
        (el) => el.name === 'Add Policy'
      );
      const canAdd = isAddingPolicyAvailable && (sub.info.plan == "Premium" || sub.info.stats.policiesCount < 1);
      setCanAddPolicy(canAdd!);
    }
  }, [sub.info]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.searchWrapper}>
          <SearchInput
            value={policiesPageFilter.searchValue}
            onChange={handleInputChange}
            clearInput={clearInput}
            placeholder="Search policies"
            outlined
          />
        </div>
        <div>
          {canAddPolicy && (
            <Button
              variant="primary"
              onClick={() => setAddPolicyFormOpen(true)}
              aria-label="Open add policy form."
            >
              Add policy
            </Button>
          )}
        </div>
      </div>

      {isLoading ? (
        <Preloader />
      ) : policiesData.length > 0 ? (
        <PoliciesTable />
      ) : (
        <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
      )}

      <Modal
        open={addPolicyFormOpen}
        onClose={() => setAddPolicyFormOpen(false)}
        title="Add time off policy"
      >
        <AddTimeOffPolicyForm
          handleCLose={() => setAddPolicyFormOpen(false)}
          handleSubmit={handleAddPolicy}
        />
      </Modal>
    </div>
  );
};

export default TimeOffPolicies;
