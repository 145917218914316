export const setTimeInDate = (
  date: Date | string,
  hours: number,
  minutes: number
): Date => {
  const dateCopy = new Date(date);
  dateCopy.setHours(hours, minutes, 0, 0);

  return dateCopy;
};
