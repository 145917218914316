import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import styles from './JoinedDateAccrualOption.module.scss';
import { Frequency } from 'helpers/Types/Enums/Frequency';
import { AccrualOptionProps } from '../AccrualOptions/AccrualOptions';

export const accrualFrequencyOptions = [
  { label: 'Day', value: String(Frequency.Day) },
  { label: 'Week', value: String(Frequency.Week) },
  { label: 'Month', value: String(Frequency.Month) },
  { label: 'Year', value: String(Frequency.Year) },
];

export const JoinedDateAccrualOption = ({isFullWidth}:AccrualOptionProps) => {
  return (
    <div className={`${isFullWidth ? '' : styles.wrapper}`}>
      <div className={`${isFullWidth ? styles.containerFullWidth : styles.container}`}>
        <ValidationTextInput
          label="Maximum accrual amount"
          name="maxAccrualAmount"
          type="text"
          description="hours per year"
        />
      </div>
      <div className={`${isFullWidth ? styles.containerFullWidth : styles.container}`}>
        <CustomFormikSelect
          label="Accrual frequency"
          name="accrualFrequency"
          options={accrualFrequencyOptions}
        />
      </div>
    </div>
  );
};

export default JoinedDateAccrualOption;