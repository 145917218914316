import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import type { IFetchToDoParams, IToDoListInitialState } from './toDoListTypes';
import { getToDosListAction, getToDosListInfiniteScrollAction } from './toDoListActions';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import type { RootState } from 'app/store';

const initialState: IToDoListInitialState = {
    toDos: [],
    totalCount: 0,
    filter: {
        searchValue: '',
        skip: 0,
        take: 20,
    },
    isLoading: false,
    errorMessage: '',
}

export const toDoListSlice = createSlice({
    name: '@@toDosList',
    initialState,
    reducers : {
      setToDoPageFilter: (state, action: PayloadAction<IFetchToDoParams>) => {
        state.filter = action.payload;
      },
      removeToDoFromList: (state, action: PayloadAction<number>) => {
        state.toDos = state.toDos.filter(e => e.id !== action.payload);
        state.totalCount -= 1;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getToDosListAction.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getToDosListAction.fulfilled, (state, action) => {
        state.toDos = action.payload.list;
        state.totalCount = action.payload.count;
        state.isLoading = false;
      });
      builder.addCase(getToDosListInfiniteScrollAction.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getToDosListInfiniteScrollAction.fulfilled, (state, action) => {
        state.toDos.push(...action.payload.list);
        state.totalCount = action.payload.count;
        state.isLoading = false;
      });
      builder.addMatcher(isFulfilledAction, (state) => {
        state.errorMessage = '';
        state.isLoading = false;
      });
      builder.addMatcher(isRejectedAction, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload;
      });
    },
});

export const { setToDoPageFilter, removeToDoFromList } = toDoListSlice.actions;

export const isLoadingToDosListSelector = createSelector(
  [(state: RootState) => state.toDoList.isLoading],
  (isLoading) => isLoading
);

export const toDosListSelector = createSelector(
  [(state: RootState) => state.toDoList.toDos],
  (toDos) => toDos
);

export const toDosListCountSelector = createSelector(
  [(state: RootState) => state.toDoList.totalCount],
  (totalCount) => totalCount
);

export const filterToDoListSelector = createSelector(
  [(state: RootState) => state.toDoList.filter],
  (filter) => filter
);