import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchOrganizationMembersList } from "api/membersList";
import { AppDispatch } from "app/store";
import { IFetchMembersParams, IMembersListItem } from "features/members/membersTypes";
import { thunkErrorHandler, MyKnownError } from "features/thunkError";

export const getFilteredPanelMembers = createAsyncThunk<
  { list: IMembersListItem[]; count: number },
  IFetchMembersParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@membersList/getFilteredPanelMembers', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationMembersList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
