import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IStatsData, ITrackerInitialState } from './trackerTypes';
import { getDailyCounter } from './trackerActions';
import { ControlPosition } from 'react-draggable';
import { SingleValue } from 'react-select';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';

const initialState: ITrackerInitialState = {
  total: null,
  counter: 0,
  visible: false,
  active: false,
  position: { x: 100, y: 100 },
  project: null,
  interval: null,
  isLoading: false
};

export const trackerSlice = createSlice({
  name: '@@tracker',
  initialState,
  reducers: {
    setTrackerTotalState: (state, action: PayloadAction<IStatsData>) => {
      state.total = action.payload.counter;
    },
    setTrackerCounterState: (state, action: PayloadAction<IStatsData>) => {
      state.counter = action.payload.counter;
    },
    setTrackerActiveState: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    setTrackerVisibleState: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setTrackerPositionState: (state, action: PayloadAction<ControlPosition>) => {
      state.position = action.payload;
    },
    setTrackerIntervalState: (state, action: PayloadAction<any>) => {
      state.interval = action.payload;
    },
    setTrackerProjectState: (state, action: PayloadAction<SingleValue<SelectOption> | null>) => {
      state.project = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDailyCounter.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDailyCounter.fulfilled, (state, action) => {
      state.total = action.payload.counter;
      state.isLoading = false;
    });
  },
});

export const { 
  setTrackerTotalState,
  setTrackerCounterState, 
  setTrackerActiveState,
  setTrackerVisibleState,
  setTrackerPositionState,
  setTrackerIntervalState,
  setTrackerProjectState
} = trackerSlice.actions;

export const trackerTotalSelector = createSelector(
  [(state: RootState) => state.trackerInfo.total],
  (counter) => counter
);

export const trackerCounterSelector = createSelector(
  [(state: RootState) => state.trackerInfo.counter],
  (counter) => counter
);

export const trackerVisibleSelector = createSelector(
  [(state: RootState) => state.trackerInfo.visible],
  (counter) => counter
);

export const trackerActiveSelector = createSelector(
  [(state: RootState) => state.trackerInfo.active],
  (counter) => counter
);

export const trackerPositionSelector = createSelector(
  [(state: RootState) => state.trackerInfo.position],
  (counter) => counter
);

export const trackerProjectSelector = createSelector(
  [(state: RootState) => state.trackerInfo.project],
  (counter) => counter
);

export const trackerIntervalSelector = createSelector(
  [(state: RootState) => state.trackerInfo.interval],
  (counter) => counter
);
