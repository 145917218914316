import { log } from 'console';
import client from '../axiosInstance';
import type { IFetchToDoParams } from 'features/toDosList/toDoListTypes';

export const fetchToDosFilterList = async (params?: IFetchToDoParams) => {
    if (params) {
        const { searchValue, skip, take } = params;
        const searchParams = new URLSearchParams({
            ...( searchValue && searchValue.length && { search: searchValue } ),
            skip: skip.toString(),
            take: take.toString(),
        });
        return await client.get(`/todo/search`, {
            params: searchParams,
        });
    } else {
        return await client.get(`/todo/search`);
    }
}

export const fetchToDosBaseModelList = async (params?: IFetchToDoParams) => {
    return await client.get(`/todo/base`);
}