import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addNewProject,
  deleteProject,
  fetchOrganizationProjectsList,
} from 'api/projectsList';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IAddingProjectData,
  IFetchProjectsParams,
  IProjectsListItem,
} from './projectsListTypes';
import { toast } from 'react-toastify';
import { removeProjectReducer } from './projectsListSlice';
import { getSubscription } from 'features/subscription/subscriptionActions';

export const getProjectsList = createAsyncThunk<
  { list: IProjectsListItem[]; count: number },
  IFetchProjectsParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectsList/getProjectsList', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationProjectsList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getProjectsListInfiniteScroll = createAsyncThunk<
  { list: IProjectsListItem[]; count: number },
  IFetchProjectsParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectsList/getProjectsListInfiniteScroll', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationProjectsList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const addProject = createAsyncThunk<
  void,
  IAddingProjectData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectsList/addProjects', async (data, thunkApi) => {
  try {
    const res = await addNewProject(data);
    if (res.status === 201) {
      thunkApi.dispatch(getProjectsList());
      thunkApi.dispatch(getSubscription());
      toast.success('New Project Created!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const removeProject = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectsList/removeProject', async (projectId, thunkApi) => {
  try {
    const res = await deleteProject(projectId);
    if (res.status === 204) {
      thunkApi.dispatch(removeProjectReducer(projectId));
      thunkApi.dispatch(getSubscription());
      toast.success('Project Deleted!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
