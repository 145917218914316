import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addNewPolicy,
  deletePolicy,
  fetchPoliciesList,
  fetchPoliciesListByMemberId,
} from 'api/policiesList';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IAddingPolicyData,
  IFetchPoliciesParams,
  IPoliciesListItem,
} from './policiesListTypes';
import { removePolicyReducer } from './policiesListSlice';
import { getSubscription } from 'features/subscription/subscriptionActions';

export const getPoliciesList = createAsyncThunk<
  { list: IPoliciesListItem[]; count: number },
  IFetchPoliciesParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policiesList/getPoliciesList', async (params, thunkApi) => {
  try {
    const res = await fetchPoliciesList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getPoliciesListInfiniteScroll = createAsyncThunk<
  { list: IPoliciesListItem[]; count: number },
  IFetchPoliciesParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policiesList/getPoliciesListInfiniteScroll', async (params, thunkApi) => {
  try {
    const res = await fetchPoliciesList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getPoliciesListByMemberId = createAsyncThunk<
  { list: IPoliciesListItem[]; count: number },
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policiesList/getPoliciesListByMemberId', async (memberId, thunkApi) => {
  try {
    const res = await fetchPoliciesListByMemberId(memberId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const addPolicy = createAsyncThunk<
  void,
  IAddingPolicyData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policiesList/addPolicy', async (data, thunkApi) => {
  try {
    const res = await addNewPolicy(data);
    if (res.status === 200) {
      thunkApi.dispatch(getPoliciesList());
      thunkApi.dispatch(getSubscription());
      toast.success('New Policy Added!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const removePolicy = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policiesList/removePolicy', async (policyId, thunkApi) => {
  try {
    const res = await deletePolicy(policyId);
    if (res.status === 204) {
      thunkApi.dispatch(removePolicyReducer(policyId));
      thunkApi.dispatch(getSubscription());
      toast.success('Policy Deleted!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
