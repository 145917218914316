import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IGetTimeOffReportsParams,
  ITimeOffReportsData,
  TimeOffReportsParamsForDownloading,
} from './timeOffReportsTypes';
import { setIsReportDownloadingState } from './timeOffReportsSlice';
import { downloadTimeOffReports, fetchTimeOffReports } from 'api/timeOffReports';

export const getTimeOffReports = createAsyncThunk<
  ITimeOffReportsData,
  IGetTimeOffReportsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffReport/getTimeOffReports', async (params, thunkApi) => {
  try {
    const res = await fetchTimeOffReports(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const exportTimeOffReports = createAsyncThunk<
  void,
  TimeOffReportsParamsForDownloading,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffReport/exportTimeOffReports', async (params, thunkApi) => {
  try {
    console.log('exportTimeOffReports');
    thunkApi.dispatch(setIsReportDownloadingState(true));
    await downloadTimeOffReports(params);
    thunkApi.dispatch(setIsReportDownloadingState(false));
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
