import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { OIDCInfoUserSelector } from 'features/user/userSlice';
import { signInRedirect } from 'features/user/userActions';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { useNavigate } from 'react-router-dom';

interface AuthProtectedRouteProps {
  element: ReactNode;
}

const AuthProtectedRoute: FC<AuthProtectedRouteProps> = ({ element }) => {
  const user = useSelector(OIDCInfoUserSelector);
  const subscription = useSelector(subscriptionInfoSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (!user) {
    dispatch(signInRedirect());
  } else if (subscription.info != null && subscription.info.plan == "Premium" && !subscription.info.active) {
    window.location.href = "/subscribe";
  }

  return <>{!user ? null : element}</>;
};

export default AuthProtectedRoute;
