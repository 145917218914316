import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import { useSelector } from 'react-redux';
import { isLoadingToDosListSelector, toDosListCountSelector, toDosListSelector } from 'features/toDosList/toDoListSlice';
import Preloader from 'components/shared/Preloader';
import ToDosTable from 'components/toDos/ToDosTable/ToDosTable';

const ActiveToDos = () => {
    const isLoading = useSelector(isLoadingToDosListSelector);
    const toDoListCount = useSelector(toDosListCountSelector)
    
    const emptyMessage = `There are no ToDo's here.`;
    
    return (
        <>
            {isLoading ? (
                <Preloader />
        ) : (
            <>
                {toDoListCount ? (
                    <ToDosTable/>
                ) : (
                    <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
                ) }
            </>
        )}
        </>
    );
}

export default ActiveToDos;