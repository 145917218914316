import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { setTrackerVisibleState, trackerCounterSelector } from 'features/tracker/trackerSlice';
import styles from './MiniTracker.module.scss';
import { TimesheetsIcon } from 'assets/icons/Menu/TimesheetsIcon';
import { ArrowIcon } from 'assets/icons/ArrowIcon';

interface MiniTrackerProps { }

const MiniTracker: FC<MiniTrackerProps> = () => {

  const dispatch = useAppDispatch();
  const counter = useSelector(trackerCounterSelector);

  const formatItem = (value: number) => {
    return value >= 10 ? value : `0${value}`;
  };

  const formatNumber = (value: number) => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;
    return `${formatItem(hours)}:${formatItem(minutes)}:${formatItem(seconds)}`;
  };

  const handleClick = () => {
    dispatch(setTrackerVisibleState(true));
  }

  return (
    <a className={styles.link} onClick={handleClick}>
      <TimesheetsIcon />
      {formatNumber(counter)}
      <div className={styles.arrow}>
        <ArrowIcon />
      </div>
    </a>
  );
};

export default MiniTracker;
