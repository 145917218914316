import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import styles from './WorkedHoursAccrualOption.module.scss';
import { AccrualOptionProps } from 'helpers/Types/AccrualOptionProps';

export const WorkedHoursAccrualOption = ({isFullWidth}:AccrualOptionProps) => {
  return (
    <>
      <div className={`${isFullWidth ? styles.maxAccrualAmountWrapperFullWidth : styles.maxAccrualAmountWrapper}`}>
        <ValidationTextInput
          label="Maximum accrual amount"
          name="maxAccrualAmount"
          type="text"
          description="hours per year"
        />
      </div>
      <div className={`${isFullWidth ? styles.hoursAccuredAndHoursWorkedWrapperFullWidth : styles.hoursAccuredAndHoursWorkedWrapper }`}>
        <div className={`${isFullWidth ? styles.hoursAccuredWrapperFullWidth : styles.hoursAccuredWrapper}`}>
          <div className={`${isFullWidth ? styles.hoursAccuredInputWrapperFullWidth : styles.hoursAccuredInputWrapper}`}>
            <ValidationTextInput
              label="Ammount accured"
              name="accuredHoursAmount"
              type="text"
            />
          </div>
          <div className={`${styles.textWrapper} ${isFullWidth ? styles.hoursAccuredTextWrapperFullWidth : styles.hoursAccuredTextWrapper}`}>
            hour(s) accrued for every
          </div>
        </div>
          <div className={`${isFullWidth ? styles.hoursWorkedWrapperFullWidth : styles.hoursWorkedWrapper}`}>
            <div className={`${isFullWidth ? styles.hoursWorkedInputWrapperFullWidth : styles.hoursWorkedInputWrapper}`}>
              <ValidationTextInput
                name="workedHoursAmount"
                type="text"
              />
            </div>
          <div className={`${styles.textWrapper} ${isFullWidth ? styles.hoursWorkedTextWrapperFullWidth : styles.hoursWorkedTextWrapper}`}>
            hours worked
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkedHoursAccrualOption;
