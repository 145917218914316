import type { FC } from 'react'
import type { IPageTab } from 'components/shared/PageTabs/PageTabs';
import {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useRef,
} from "react";
import PageTabs from 'components/shared/PageTabs';
import { Outlet } from 'react-router-dom';
import debounce from 'lodash.debounce';
import Modal from 'components/shared/Modal';
import SearchInput from 'components/shared/forms/SearchForm/SearchInput';
import Button from 'components/shared/Button';
import { useSelector } from "react-redux";
import { permissionsUserSelector } from 'features/user/userSlice';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { startsWithNoSpace } from 'helpers/regularExpressions';
import { useAppDispatch } from 'app/store';
import { filterToDoListSelector, setToDoPageFilter } from 'features/toDosList/toDoListSlice';
import { addToDoAction, getToDosListAction } from 'features/toDosList/toDoListActions';
import { getProjectsFilterList } from 'features/projectsFilterList/projectsFilterListActions';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import styles from './ToDo.module.scss';
import { projectsFilterListSelector } from 'features/projectsFilterList/projectsFilterListSlice';
import AddToDoForm from 'components/toDos/AddToDoForm/AddToDoForm';
import type { IToDoAddModel } from 'features/toDosList/toDoListTypes';

const tabsData : IPageTab[] = [
    {
      path: `/todo`,
      name: `Active ToDo's`,
    },
  ];


const ToDo: FC = () => {
  const [canAddToDo, setCanAddToDo] = useState<boolean>(false);
  const [addToDoFormOpen, setAddToDoFormOpen] = useState<boolean>(false);
  const didMountRef = useRef<boolean>(false);
  const openAddToDoForm = () => setAddToDoFormOpen(true);
  const closeAddToDoForm = () => setAddToDoFormOpen(false);
  
  const dispatch = useAppDispatch();

  const projects = useSelector(projectsFilterListSelector);
  const permissions = useSelector(permissionsUserSelector);
  const subscription = useSelector(subscriptionInfoSelector);
  const toDoPageFilter = useSelector(filterToDoListSelector);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (startsWithNoSpace.test(text)) {
      dispatch(setToDoPageFilter({ searchValue: text, skip: 0, take: 20 }));
    }
  };

  const clearInput = () => {
    dispatch(setToDoPageFilter({ searchValue: '', skip: 0, take: 20 }));
  };

  const debouncedSearch = useCallback(
    debounce((searchString: string) => {
      dispatch(
        getToDosListAction({ searchValue: searchString, skip: 0, take: 20 })
      );
    }, 500),
    []
  );

  const onSubmit = (data: IToDoAddModel) => {
    dispatch(addToDoAction(data));
  };

  useEffect(() => {
    dispatch(getToDosListAction(toDoPageFilter));
    dispatch(getProjectsFilterList());
    dispatch(getMembersFilterList());
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      debouncedSearch(toDoPageFilter.searchValue);
    }
    didMountRef.current = true;
  }, [toDoPageFilter.searchValue]);

  useEffect(() => {
    if (subscription.info) {
      const isAddingToDoAvailable = permissions?.some(
        (el) => el.name === 'Add ToDo'
      );

      const canAdd = Boolean(isAddingToDoAvailable &&
        (subscription.info.plan == "Premium"|| subscription.info.stats.projectsCount > 0));

      setCanAddToDo(canAdd);
    }
  }, [subscription.info]);

    return (
        <div className={styles.wrapper}>
          <div className={styles.controls}>
            <PageTabs tabsData={tabsData} />
            <div className={styles.searchAndActionBtnWrapper}>
              <div className={styles.searchWrapper}>
                <SearchInput
                value={toDoPageFilter.searchValue}
                onChange={handleInputChange}
                clearInput={clearInput}
                placeholder="Search ToDo's"
                outlined
              />
              </div>
              {canAddToDo && (
                <div className={styles.btnWrapper}>
                  <Button
                    variant='primary'
                    onClick={openAddToDoForm}
                    aria-label='Open ToDo adding form.'
                  >
                    Add ToDo
                  </Button>
                </div>
              )}
            </div>
          </div>

          <Outlet />

          <Modal
            title='Add ToDo'
            open={addToDoFormOpen}
            onClose={closeAddToDoForm}
          >
            <AddToDoForm
              organizationProjects={projects}
              onSubmit={onSubmit}
              closeAddToDoForm={closeAddToDoForm}
            />
          </Modal>
        </div>
    );
}

export default ToDo;