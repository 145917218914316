import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const SENTRY_DSN =
  'https://c842709e54017670a27c101673448714@o4506572127600640.ingest.sentry.io/4506572155191296';
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  denyUrls: [String(process.env.REACT_APP_BACKEND_API)],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  enabled: String(process.env.REACT_APP_SENTRY_ENABLED) === 'true',
});
