import { FC, useEffect, useRef, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { SelectOption } from 'components/shared/forms/AutocompleteSelect/AutocompleteSelect';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import { IProjectShortInfo } from 'features/projectInfo/projectInfoTypes';

interface IProjectsFilterProps {
  projects: IProjectShortInfo[],
}

export type IProjectsURLSearchParams = Partial<{
  projectsIds: string[];
}>;

const ProjectsFilter: FC<IProjectsFilterProps> = ({
  projects,
}) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const projectsQuery = searchParams.getAll('projectsIds');
  const isMountedRef = useRef(false);
  const projectsFromQueries = projects.filter((project) =>
    projectsQuery.includes(String(project.id))
  );
  const allProjectsOptions = projects.map((project) => ({
    label: project.name,
    value: String(project.id),
  }));

  const [projectsOptions, setProjectsOptions] =
    useState<MultiValue<SelectOption> | null>(null);

  const handleProjectsOptionsChange = (
    option: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setProjectsOptions(option as MultiValue<SelectOption>);
  };

  useEffect(() => {
    if (projectsFromQueries && projectsFromQueries.length > 0) {
      setProjectsOptions(
        projectsFromQueries.map((project) => ({
          label: project.name,
          value: String(project.id),
        }))
      );
    }
  }, [projects]);

  useEffect(() => {
    if (isMountedRef.current) {
      const params = Object.fromEntries([...Array.from(searchParams)]);
      const URLSearchParams: IProjectsURLSearchParams = {
        ...params,
      };

      if (projectsOptions && projectsOptions.length > 0) {
        URLSearchParams.projectsIds = projectsOptions.map(
          (option) => option.value
        );
      } else {
        delete URLSearchParams.projectsIds;
      }

      navigate({
        pathname: pathname,
        search: createSearchParams(
          URLSearchParams as URLSearchParamsInit
        ).toString(),
      });
    }

    isMountedRef.current = true;
  }, [projectsOptions]);

  return (
    <>
      <DropdownSelect
        label="Projects"
        value={projectsOptions}
        options={allProjectsOptions}
        onChange={(value) => {
          handleProjectsOptionsChange(value);
        }}
        isSearchable
        blurInputOnSelect={false}
        isMulti
      />
    </>
  );
};

export default ProjectsFilter;
