import * as Yup from 'yup';
import { AnnualAccrualOption } from '../AnnualAccrualOption/AnnualAccrualOption';
import { WorkedHoursAccrualOption } from '../WorkedHoursAccrualOption/WorkedHoursAccrualOption';
import { JoinedDateAccrualOption, accrualFrequencyOptions } from '../JoinedDateAccrualOption/JoinedDateAccrualOption';
import { MaxNumberOneMillion } from 'constants/formsConstants';
import { HourCountInYear } from 'constants/timeConstanst';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import styles from './AccrualOptions.module.scss';
import { IPolicyInfo } from 'features/policyInfo/policyInfoTypes';
import { AccrualOptionId } from 'helpers/Types/Enums/AccrualOptionId';
import { Frequency } from 'helpers/Types/Enums/Frequency';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';

export interface AccrualOptionProps {
  isFullWidth: boolean
}

interface AccrualOptionsProps extends AccrualOptionProps{
  id : string
}

const accrualOptions = [
    { label: 'None', value: String(AccrualOptionId.None) },
    { label: 'Annual', value: String(AccrualOptionId.Annual) },
    { label: 'Hours Worked', value: String(AccrualOptionId.HoursWorked) },
    { label: 'Joined Date', value: String(AccrualOptionId.JoinedDate) }
  ];

export const accrualOptionsValidationSchema = {
    maxAccrualAmount: Yup.number()
      .when('accrualOption',
      {
        is: (accrualOption: ISelectOption) => {
          const accrualOptionValue = Number(accrualOption.value); 

          return  accrualOptionValue === AccrualOptionId.Annual ||
            accrualOptionValue === AccrualOptionId.HoursWorked ||
            accrualOptionValue === AccrualOptionId.JoinedDate 
        },
        then: Yup.number()
          .typeError('Maximum accrual amount must be a `number`')
          .min(1, "Maximum accrual amount must be greater than 0.")
          .max(HourCountInYear, `Maximum accrual amount must be less than ${HourCountInYear}.`)
          .required("Maximum accrual amount required"),
       
      }),
    accuredHoursAmount: Yup.number()
      .when('accrualOption',
      {
          is: (accrualOption: ISelectOption) => {return Number(accrualOption.value) === AccrualOptionId.HoursWorked},
          then:  Yup.number()
            .typeError('Accured hours amount must be a `number`')
            .min(1, "Accured hours amount must be greater than 0.")
            .max(MaxNumberOneMillion, `Accured hours amount must be less than or equal to ${MaxNumberOneMillion}.`)
            .required("Accured hours amount required"),
          otherwise: Yup.number()
            .notRequired()
      }),
    workedHoursAmount: Yup.number()
      .when('accrualOption',
      {
          is: (accrualOption: ISelectOption) => {return Number(accrualOption.value) === AccrualOptionId.HoursWorked},
          then: Yup.number()
            .typeError('Worked hours amount must be a `number`')
            .min(1, "Worked hours amount must be greater than 0.")
            .max(MaxNumberOneMillion, `Worked hours amount  must be less than or equal to ${MaxNumberOneMillion}.`)
            .required("Worked hours amount required"),
          otherwise: Yup.number()
            .notRequired()
      }),
    accrualFrequency: Yup.mixed()
      .when('accrualOption',
      {
        is: (accrualOption: ISelectOption) => {return Number(accrualOption.value) === AccrualOptionId.JoinedDate},
        then: Yup.mixed().required(),
        otherwise: Yup.mixed().notRequired()
      })
}

export const accrualOptionsInitValues = {
    accrualOption: accrualOptions[0],
    maxAccrualAmount: undefined,
    accrualFrequency: accrualFrequencyOptions[Frequency.Month],
    accuredHoursAmount: undefined,
    workedHoursAmount: undefined
}

export const accrualOptionsInitExistingValues = (policy : IPolicyInfo) => {
  return {
    accrualOption: policy.accrualOptionId === undefined
      ? accrualOptions[0]
      : accrualOptions[policy.accrualOptionId],
    maxAccrualAmount: policy.maxAccrualAmount,
    accrualFrequency: 
      policy.accrualFrequency === undefined ||
      policy.accrualFrequency === null
        ? accrualFrequencyOptions[Frequency.Month]
        : accrualFrequencyOptions[policy.accrualFrequency],
    accuredHoursAmount: policy.accuredHoursAmount,
    workedHoursAmount: policy.workedHoursAmount
  }
}

const renderSwitch = (id : string, isFullWidth: boolean) => {
  switch(Number(id)) {
    case AccrualOptionId.Annual:
      return <AnnualAccrualOption isFullWidth={isFullWidth} />;
    case  AccrualOptionId.HoursWorked:
      return <WorkedHoursAccrualOption isFullWidth={isFullWidth} />;
    case AccrualOptionId.JoinedDate:
      return <JoinedDateAccrualOption isFullWidth={isFullWidth} />;
    default:
      return '';
  }
}

export const AccrualOptions = ({id, isFullWidth} : AccrualOptionsProps) => 
  <>
    <CustomFormikSelect
      label={'Schedule of accruals'}
      name="accrualOption"
      options={accrualOptions}
    />
              
    <div className={styles.accrualOptionComponentWrapper}>
      {renderSwitch(id, isFullWidth)}         
    </div>
  </>

export default AccrualOptions;