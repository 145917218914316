import { useState, FC, useEffect } from "react";
import styles from './CircleList.module.scss';
import CircleItem from "./CircleItem";

export interface IListItem {
  id: number;
  label: string;
  isSelected: boolean;
  data?: any;
}

interface ICircleListProps {
  realItems: IListItem[];
  maxCountCircle?: number;
  updateItems: (filteredItems: IListItem[]) => void;
}

interface ISelectedCircles {
  [key: number]: IListItem;
}

const CircleList: FC<ICircleListProps> = ({
  realItems,
  maxCountCircle = 5,
  updateItems
}) => {

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [items, setItems] = useState(realItems);
  const [selectedCircles, setSelectedCircles] = useState<ISelectedCircles>({});
  const [nextZIndex, setNextZIndex] = useState<number>(items.length);
  const [filteredItems, setFilteredItems] = useState<IListItem[]>(items);

  useEffect(() => {
    // When isChecked == true => items = [..., ishcecked=true, ishcecked=false, ...];
    const sortedItems = [...realItems].sort((a, b) => {
      if (a.isSelected && !b.isSelected) {
        return -1;
      }
      if (!a.isSelected && b.isSelected) {
        return 1;
      }
      return 0;
    });

    setItems(sortedItems);
  }, [realItems]);

  useEffect(() => {
    const initialSelectedItems = items.filter(item => item.isSelected);
    const selectedCirclesMap: ISelectedCircles = {};
    initialSelectedItems.forEach((item) => {
      selectedCirclesMap[item.id] = item;
    });
    setSelectedCircles(selectedCirclesMap);
    setNextZIndex(items.length);
  }, [items]);

  useEffect(() => {
    updateItems(filteredItems);
  }, [filteredItems]);

  const handleCircleClick = (selectedItem: IListItem) => {
    const newSelectedCircles = { ...selectedCircles };

    if (newSelectedCircles[selectedItem.id]) {
      delete newSelectedCircles[selectedItem.id];
    } else {
      newSelectedCircles[selectedItem.id] = selectedItem;
    }

    const newFilteredItems = Object.values(newSelectedCircles);
    setSelectedCircles(newSelectedCircles);
    setFilteredItems(newFilteredItems);
  };

  return (
    <div className={styles.membersContainer}>
      {items.slice(0, maxCountCircle).map((item, index) => (
        <CircleItem
          key={item.id}
          data={{ label: item.label[0] }}
          index={index}
          nextZIndex={nextZIndex}
          isSelected={selectedCircles[item.id] ? true : false}
          isHovered={hoveredIndex === index}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => handleCircleClick(item)}
        />
      ))}
    </div>
  );
};

export default CircleList;
