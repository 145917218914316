import type { FC } from 'react';
import styles from './TeamOverview.module.scss';
import PageTitle from 'components/shared/PageTitle';
import FilterPanel from 'components/teamOverview/filterPanel';

const TeamOverview: FC = () => {

  return (
      <div className={styles.wrapper}>
        <PageTitle title={"Team overview"} />
        <FilterPanel/>      
      </div>
  );
};

export default TeamOverview;
