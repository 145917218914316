import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import ProjectsFilter from './ProjectsFilter';
import MembersFilter from './MembersFilter';
import { ReportsFilterProps } from 'components/reports/timeAndActivity/TimeAndActivityReportsFilter/TimeAndActivityReportsFilter';
import CustomCheckbox from 'components/shared/forms/CustomCheckbox';
import Button from 'components/shared/Button';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { USER_ROLE } from 'features/user/userTypes';
import styles from './TimeOffReportsFilter.module.scss';
import { ITimeOffReportsURLSearchParams } from 'features/timeOffReports/timeOffReportsTypes';

const TimeOffReportsFilter: FC<ReportsFilterProps> = ({ closeFilters }) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const userInfo = useSelector(selectInfoUserSelector);
  const navigate = useNavigate();
  const isMountedRef = useRef(false);
  const isMembersFilterAvailable = userInfo?.role !== USER_ROLE.User;
  const isMyReportsTab = pathname.endsWith('/my');
  const sumDateRangesQuery = searchParams.get('sumDateRanges');
  const defaultSumDateRanges = !!(
    sumDateRangesQuery && sumDateRangesQuery === 'true'
  );
  const [sumDateRanges, setSumDateRanges] = useState(defaultSumDateRanges);
  const handleSumDateRangesChange = () => setSumDateRanges(!sumDateRanges);

  const clearFilters = () => {
    const params = Object.fromEntries([...Array.from(searchParams)]);
    const URLSearchParams: ITimeOffReportsURLSearchParams = {
      ...params,
    };

    delete URLSearchParams.sumDateRanges;
    delete URLSearchParams.projectsIds;
    delete URLSearchParams.membersIds;

    navigate({
      pathname: pathname,
      search: createSearchParams(
        URLSearchParams as URLSearchParamsInit
      ).toString(),
    });
    closeFilters();
  };

  useEffect(() => {
    if (isMountedRef.current) {
      const params = Object.fromEntries([...Array.from(searchParams)]);
      const URLSearchParams: ITimeOffReportsURLSearchParams = {
        ...params,
        projectsIds: searchParams.getAll('projectsIds'),
        membersIds: searchParams.getAll('membersIds'),
      };

      if (sumDateRanges) {
        URLSearchParams.sumDateRanges = String(sumDateRanges);
      } else {
        delete URLSearchParams.sumDateRanges;
      }

      navigate({
        pathname: pathname,
        search: createSearchParams(
          URLSearchParams as URLSearchParamsInit
        ).toString(),
      });
    }
    isMountedRef.current = true;
  }, [sumDateRanges]);

  return (
    <>
      <div className={styles.wrapper}>
        <ProjectsFilter />
        {isMembersFilterAvailable && !isMyReportsTab && <MembersFilter />}

        <CustomCheckbox
          label="Sum date ranges"
          isChecked={sumDateRanges}
          handleChange={handleSumDateRangesChange}
        />

        <Button onClick={clearFilters}>Clear filters</Button>
      </div>
    </>
  );
};

export default TimeOffReportsFilter;
