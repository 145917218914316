import { useAppDispatch } from "app/store";
import { useSelector } from 'react-redux';
import { getToDosListInfiniteScrollAction } from "features/toDosList/toDoListActions";
import type { FC } from "react";
import { filterToDoListSelector, isLoadingToDosListSelector, toDosListCountSelector, toDosListSelector } from "features/toDosList/toDoListSlice";
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import PulseLoader from 'react-spinners/PulseLoader';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';
import { FixedSizeList, ListChildComponentProps } from "react-window";
import type { IToDoListItem } from "features/toDosList/toDoListTypes";
import ActionsPopover from "components/shared/ActionsPopover";
import { ToDoStatus } from "features/toDoInfo/toDoInfoTypes";


const toDosTableColumns = [
    { name: 'ToDo name'},
    { name: 'Project name' },
    { name: 'Assignee member' },
    { name: 'Status' },
    { name: 'Actions' }
];

const ToDosTable : FC = () => {
    const isToDosLoading = useSelector(isLoadingToDosListSelector);
    const toDosTotalCount = useSelector(toDosListCountSelector);
    const toDosPageFilter = useSelector(filterToDoListSelector);
    const infiniteToDosList = useSelector(toDosListSelector);
    const dispatch = useAppDispatch();

    const hasNextPage = infiniteToDosList.length < toDosTotalCount;
    const loadNextPage = async () => {
        dispatch(
            getToDosListInfiniteScrollAction({
            ...toDosPageFilter,
            skip: infiniteToDosList.length,
            take: 20,
          })
        );
    };
    
    const itemCount = hasNextPage
        ? infiniteToDosList.length + 1
        : infiniteToDosList.length;
    
    const loadMoreItems = isToDosLoading ? () => {} : loadNextPage;

    const isItemLoaded = (index: number) => {
        return !hasNextPage || index < infiniteToDosList.length;
    }
        
    const ToDosTableItem: FC<ListChildComponentProps<IToDoListItem[]>> = (
        props
    ) => {
        const content = infiniteToDosList[props.index];
        if (!isItemLoaded(props.index))
            return (
              <div style={props.style} className={styles.loadNextTableIndicator}>
                  <PulseLoader
                  color="#0168fa"
                  size={8}
                  margin={5}
                  speedMultiplier={1}
                  />
              </div>
            );
    
        return (
            <div style={props.style}>
                <div className={`${styles.fRow} ${styles.fBodyRow}`}>
                    <div
                        className={`${styles.fCell} ${styles.fBodyCell}`}
                    >
                        <span className={`${styles.fBodyCellContent}`}>{content.name}</span>
                    </div>
                    <div
                        className={`${styles.fCell} ${styles.fBodyCell}`}
                    >
                        <span className={`${styles.fBodyCellContent}`}>{content.project.name}</span>
                    </div>
                    <div
                        className={`${styles.fCell} ${styles.fBodyCell}`}
                    >
                        <span
                            className={`${styles.fBodyCellContent} ${
                            content.assignee === null && styles.noDataCellContent
                            }`}
                        >
                            {content.assignee !== null ? (
                                content.assignee!.name
                            ) : (
                            'No members assignee'
                            )}
                        </span>
                    </div>
                    <div
                        className={`${styles.fCell} ${styles.fBodyCell}`}
                        >
                        <span
                            className={`${styles.fBodyCellContent}`}
                        >
                            {ToDoStatus[content.status]}
                        </span>
                    </div>
                    <div className={`${styles.fCell} ${styles.fBodyCell}`}>
                        <ActionsPopover>
                          <div>Edit</div>
                          <div>Delete</div>
                        </ActionsPopover>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
          <div className={styles.fTable}>
            <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
              {toDosTableColumns.map((col) => (
                <div
                  key={col.name}
                  className={`${styles.fCell} ${styles.fHeaderCell}`}
                >
                  {col.name}
                </div>
              ))}
            </div>
            <div className={styles.fTableBody}>
              <AutoSizer>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}
                  >
                    {({ onItemsRendered, ref }) => (
                      <FixedSizeList
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        height={height}
                        overscanCount={1}
                        itemSize={70}
                        width={width}
                      >
                        {ToDosTableItem}
                      </FixedSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </div>
          </div>
        </>
      );
}

export default ToDosTable;