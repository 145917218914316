import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { getDailyStats } from 'api/trackerInfo';
import { IStatsData, IStatsParams } from './trackerTypes';

export const getDailyCounter = createAsyncThunk<
  IStatsData,
  IStatsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@tracker/getDailyCounter', async (params, thunkApi) => {
  try {
    const res = await getDailyStats(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
