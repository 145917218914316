import { type FC } from "react";
import styles from './CircleItem.module.scss';

interface ICircleProps {
  label: string;
}
interface ICircleItemProps {
  data: ICircleProps,
  index: number;
  nextZIndex: number;
  isSelected: boolean;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
}

const CircleItem: FC<ICircleItemProps> = ({ 
  data, 
  index,
  nextZIndex,
  isSelected, 
  isHovered, 
  onMouseEnter,
  onMouseLeave,
  onClick 
}) => {
  return (
    <div
      className={`${styles.circleContainer}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={{ zIndex: isSelected ? nextZIndex : index }}
    >
      <div
        className={`${styles.circle} ${isSelected ? styles.selected : ''}`}
        style={{ zIndex: isHovered ? 10000 : index }}
      >
        {data.label}
      </div>
    </div>
  );
};

export default CircleItem;
