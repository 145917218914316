import { useEffect, type FC, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import type { IToDoAddModel } from "features/toDosList/toDoListTypes";
import type { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import type { IProjectShortInfo } from "features/projectInfo/projectInfoTypes";
import styles from './AddToDoForm.module.scss';
import { useAppDispatch } from "app/store";
import { getMembersFilterList } from "features/membersFilterList/membersFilterListActions";
import { membersFilterListSelector } from "features/membersFilterList/membersFilterListSlice";

interface AddToDoFormProps {
    organizationProjects: IProjectShortInfo[];
    onSubmit: (data: IToDoAddModel) => void;
    closeAddToDoForm: () => void;
}

interface ICustomMemberByProjectSelectProps {
    label: string;
    name : string;
}

const CustomMemberByProjectSelect : FC<ICustomMemberByProjectSelectProps> = ({
    label,
    name,
}) => {
    const [members, setMembers] = useState<SelectOption[]>([]);
    const dispatch = useAppDispatch();
    const {
        values,
        touched,
      } = useFormikContext<IAddToDoFormValues>();

    const membersList = useSelector(membersFilterListSelector);

    useEffect(() => {
        setMembers(membersList.map((m) => ({
            value: String(m.id),
            label: m.name,
        })));
    }, [membersList]);

    useEffect(() => {
        if (values.project && touched.project) {
            dispatch(getMembersFilterList([Number(values.project.value)]));
        } else {
            setMembers([]);
        }
    }, [values.project, touched.project]);

    
    return(
        <CustomFormikSelect
            label={label}
            name={name}
            options={members}
            />
    );
}

interface IAddToDoFormValues {
    toDoName: string;
    project: SelectOption | null;
    member: SelectOption | null;
}

const AddToDoForm : FC<AddToDoFormProps> = ({
    organizationProjects,
    onSubmit,
    closeAddToDoForm,
}) => {

    const initialValues: IAddToDoFormValues = {
        toDoName: '',
        project: null,
        member: null,
    };

    const validationSchema = Yup.object({
        toDoName: Yup.string().trim().required('Name is required'),
        project: Yup.object().shape({
            label: Yup.string().required('Project is required'),
            value: Yup.string().required('Project is required'),
        }).required('Project is required').nullable(),
    });

    const projectOptions = organizationProjects.map((p) => ({
        value: String(p.id),
        label: p.name,
    }));

    return(
        <div className={styles.wrapper}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(
                values: typeof initialValues,
                { setSubmitting, resetForm }
                ) => {
                onSubmit({
                    name: values.toDoName,
                    projectId: values.project ? Number(values.project.value) : undefined,
                    assigneeId: values.member ? Number(values.member.value) : undefined,
                });
                setSubmitting(false);
                resetForm();
                closeAddToDoForm();
                }}
            >
                {(formik) => (
                <Form>
                    <ValidationTextInput
                    label='ToDo name'
                    name='toDoName'
                    type='text'
                    placeholder='Add a ToDo name'
                    />

                    <CustomFormikSelect
                    label={'Project'}
                    name='project'
                    options={projectOptions}
                    />

                    <CustomMemberByProjectSelect
                        label='Member'
                        name='member'/>

                    <div className={styles.controlWrapper}>
                        <Button
                            type="button"
                            onClick={() => {
                            formik.resetForm();
                            closeAddToDoForm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={formik.isSubmitting}
                            preloader={formik.isSubmitting}
                        >
                            Add
                        </Button>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    );
}

export default AddToDoForm;