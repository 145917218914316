import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import PulseLoader from 'react-spinners/PulseLoader';
import { useAppDispatch } from 'app/store';
import ProjectsActionsList from 'components/projects/ProjectsActionsList';
import ActionsPopover from 'components/shared/ActionsPopover';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import { IProjectsListItem } from 'features/projectsList/projectsListTypes';
import {
  filterProjectsListSelector,
  isLoadingProjectsListSelector,
  itemsTotalCountProjectsListSelector,
  projectsProjectsListSelector,
} from 'features/projectsList/projectsListSlice';
import {
  getProjectsListInfiniteScroll,
  removeProject,
} from 'features/projectsList/projectsListActions';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';

const projectsTableColumns = [
  { name: 'Project name' },
  { name: 'Members' },
  { name: 'Client' },
  { name: 'Actions' },
];

const ProjectsTable: FC = () => {
  const projectsTotalCount = useSelector(itemsTotalCountProjectsListSelector);
  const isProjectsLoading = useSelector(isLoadingProjectsListSelector);
  const infiniteListItems = useSelector(projectsProjectsListSelector);
  const projectsPageFilter = useSelector(filterProjectsListSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [projectIdForDelete, setProjectIdForDelete] = useState<
    number | undefined
  >(undefined);
  const [confirmDeleteProject, setConfirmDeleteProject] = useState(false);
  const handleRemove = (id: number) => {
    setProjectIdForDelete(id);
    setConfirmDeleteProject(true);
  };

  const hasNextPage = infiniteListItems.length < projectsTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getProjectsListInfiniteScroll({
        ...projectsPageFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };
  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isProjectsLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const ProjectsTableItem: FC<ListChildComponentProps<IProjectsListItem[]>> = (
    props
  ) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    const handleRowClick = () => {
      navigate(`/projects/${content.id}/edit`);
    };
    const handleMembersClick = () => {
      navigate(`/projects/${content.id}/edit/members`);
    };

    return (
      <div style={props.style}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={handleRowClick}
          >
            <span className={`${styles.fBodyCellContent}`}>{content.name}</span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={handleMembersClick}
          >
            <span
              className={`${styles.fBodyCellContent} ${
                +content.members.length <= 0 && styles.noDataCellContent
              }`}
            >
              {content.members.length > 0 ? (
                content.members.length !== 1 ? (
                  <div className={styles.fewMembersWrapper}>
                    <div>{content.members[0].name}</div>
                    <div className={styles.remainingMembers}>
                      +{String(content.members.length - 1)}
                    </div>
                  </div>
                ) : (
                  content.members[0].name
                )
              ) : (
                'No members'
              )}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={handleRowClick}
          >
            <span
              className={`${styles.fBodyCellContent} ${
                !content.client && styles.noDataCellContent
              }`}
            >
              {content.client ? content.client.name : 'No client'}
            </span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <ProjectsActionsList
                projectId={content.id}
                canDelete={content.canDelete ?? false}
                handleRemove={handleRemove}
              />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.fTable}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {projectsTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {ProjectsTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
      {projectIdForDelete && (
        <ConfirmDialog
          open={confirmDeleteProject}
          title="Confirm"
          description="Delete this project?"
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmDeleteProject(false)}
          onAccept={() => {
            dispatch(removeProject(projectIdForDelete));
          }}
        />
      )}
    </>
  );
};

export default ProjectsTable;
