import { type OptionProps } from "react-select";
import CustomCheckbox from "../../../CustomCheckbox";
import { type ISelectOption } from "../../../DropdownSelect/DropdownSelect";
import styles from './CheckBoxOption.module.scss';
import CheckboxForOption from "./CheckBoxForOption";

interface ICheckboxOptionProps {
  checkboxStates: { [key: string]: boolean };
  handleOptionChange: (data: ISelectOption) => void;
}

type ICustomCheckboxOptionProps = OptionProps<any, true, any> & ICheckboxOptionProps;

const CheckboxOption = ({ data, innerProps, isDisabled, checkboxStates, handleOptionChange }: ICustomCheckboxOptionProps) =>
  !isDisabled ? (
    <div {...innerProps}>{
      <div className={styles.optionLayout}>
        <div className={styles.optionLabel}>
          {data.label}
        </div>
        <CheckboxForOption
          label=''
          isChecked={checkboxStates[data.value] || false}
          handleChange={() => handleOptionChange(data)}
        />
      </div>
    }</div>
  ) : null;


export const CustomCheckboxOption: React.FC<any> = (props) => {
  return <CheckboxOption {...props} />;
};
