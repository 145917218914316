import { fetchToDosBaseModelList } from "api/ToDo/toDoFilterList";
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import type { IFetchItemsTemplate } from '../../helpers/Types/FetchItemsTemplate'
import type { IBaseToDoModel } from "./toDosFilterTypes";

export const getToDosFilterListAction = createAsyncThunk<
    IFetchItemsTemplate<IBaseToDoModel>,
    undefined,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
    >('@@toDosFilterList/getToDosFilterListAction', async (_, thunkApi) => {
        try {
            const response = await fetchToDosBaseModelList();
            return response.data;
        } catch (err) {
            return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
        }
    });