import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useAppDispatch } from 'app/store';
import AddClientForm from 'components/clients/AddClientForm';
import SearchInput from 'components/shared/forms/SearchForm/SearchInput';
import PageTabs from 'components/shared/PageTabs';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { IFetchClientsParams } from 'features/clientsList/clientsListTypes';
import { IAddClientData } from 'features/clientInfo/clientInfoTypes';
import {
  addClient,
  getClientsList,
} from 'features/clientsList/clientsListActions';
import {
  filterClientsListSelector,
  setClientsPageFilter,
} from 'features/clientsList/clientsListSlice';
import { startsWithNoSpace } from 'helpers/regularExpressions';
import styles from './Clients.module.scss';
import { permissionsUserSelector } from 'features/user/userSlice';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';

const tabsData = [
  {
    path: `/clients`,
    name: 'Active Clients',
  },
];

const Clients: FC = () => {
  const clientsPageFilter = useSelector(filterClientsListSelector);
  const permissions = useSelector(permissionsUserSelector);
  const [canAddClient, setCanAddClient] = useState<boolean>(false);
  const sub = useSelector(subscriptionInfoSelector);
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const [addClientFormOpen, setAddClientFormOpen] = useState(false);
  const openAddClientForm = () => setAddClientFormOpen(true);
  const closeAddClientForm = () => setAddClientFormOpen(false);

  const isAddingClientAvailable = permissions?.some(
    (el) => el.name === 'Add Client'
  );

  const onSubmit = (data: IAddClientData) => {
    dispatch(
      addClient({
        ...data,
      })
    );
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (startsWithNoSpace.test(text)) {
      dispatch(
        setClientsPageFilter({
          ...clientsPageFilter,
          searchValue: text,
          skip: 0,
          take: 20,
        })
      );
    }
  };

  const clearInput = () => {
    dispatch(
      setClientsPageFilter({
        ...clientsPageFilter,
        searchValue: '',
        skip: 0,
        take: 20,
      })
    );
  };
  const debouncedClientsFiltration = useCallback(
    debounce((params: IFetchClientsParams) => {
      dispatch(getClientsList(params));
    }, 300),
    []
  );

  useEffect(() => {
    dispatch(getClientsList(clientsPageFilter));
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      debouncedClientsFiltration(clientsPageFilter);
    }
    didMountRef.current = true;
  }, [clientsPageFilter.searchValue]);

  useEffect(() => {
    if (sub.info != null) {
      const isAddingClientAvailable = permissions?.some(
        (el) => el.name === 'Add Client'
      );
      const canAdd = isAddingClientAvailable && (sub.info.plan == "Premium" || sub.info.stats.clientsCount < 1);
      setCanAddClient(canAdd!);
    }
  }, [sub.info]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <PageTabs tabsData={tabsData} />

        <div className={styles.searchAndActionBtnWrapper}>
          <div className={styles.searchWrapper}>
            <SearchInput
              value={clientsPageFilter.searchValue}
              onChange={handleInputChange}
              clearInput={clearInput}
              placeholder="Search clients"
              outlined
            />
          </div>

          <div className={styles.btnWrapper}>
            {canAddClient && (
              <Button
                variant="primary"
                onClick={openAddClientForm}
                aria-label="Open client adding form."
              >
                Add client
              </Button>
            )}
          </div>
        </div>
      </div>

      <Outlet />

      <Modal
        title="Add client"
        open={addClientFormOpen}
        onClose={closeAddClientForm}
      >
        <AddClientForm
          onSubmit={onSubmit}
          closeAddClientForm={closeAddClientForm}
        />
      </Modal>
    </div>
  );
};

export default Clients;
