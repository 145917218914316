import React, { FC } from 'react';
import Select, {
  MultiValue,
  Options,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { FieldHookConfig, useField } from 'formik';
import styles from './CustomFormikSelect.module.scss';

export interface SelectOption {
  label: string;
  value: string;
}

export interface CustomFormikSelectProps
  extends React.HTMLAttributes<HTMLSelectElement | HTMLInputElement> {
  options: Options<SelectOption>;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
  isSearchable?: boolean;
}

const CustomFormikSelect: FC<
  CustomFormikSelectProps & FieldHookConfig<SelectOption | SelectOption[]>
> = ({
  options,
  isMulti = false,
  label,
  placeholder,
  isSearchable,
  disabled,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched } = helpers;

  const handleChange = (
    option: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => {
    option &&
      setValue(isMulti ? (option as SelectOption[]) : (option as SelectOption));
  };

  const customStyles: StylesConfig<SelectOption, typeof isMulti> = {
    control: (base, state) => ({
      ...base,
      minHeight: '44px',
      fontSize: 14,
      background: '#fff',
      borderWidth: '1.5px',
      borderColor:
        meta.touched && !!meta.error
          ? '#D80027'
          : state.isFocused
          ? '#007AFF'
          : '#E7E7E7',
      borderRadius: 8,
      outline: 'none',
      boxShadow:
        meta.touched && !!meta.error
          ? '0 0 7px #D80027'
          : state.isFocused
          ? 'inset 0 2px 2px rgb(0 0 0 / 5%), 0 0 7px rgb(1 104 250 / 50%)'
          : 'none',
      '&:hover': {},
    }),
    menu: (base) => ({
      ...base,
      fontSize: 14,
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: 8,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: 14,
      //remove unwanted border effects
      '&:hover': {},
    }),
    multiValue: (base) => ({
      ...base,
      background: '#fff',
      border: 'solid',
      borderColor: '#E7E7E7',
      borderWidth: 1,
      borderRadius: 8,
      borderLeftWidth: 7,
      borderLeftColor: '#007AFF',
      padding: '4px 0 4px 4px',
      color: '#000000',
      cursor: 'pointer',
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: 14,
      padding: '0 3px',
      color: '#000000',
    }),
    multiValueRemove: (base) => ({
      ...base,
      background: '#fff',
      borderRadius: 12,
      color: '#64748B',
      fontWeight: 400,
      '&:hover': {
        background: '#fff',
        color: '#E7E7E7',
      },
    }),
  };

  return (
    <div className={styles.root}>
      {label && (
        <label className="input-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <Select
        value={field.value}
        options={options}
        onChange={handleChange}
        onMenuClose={() => setTouched(true)}
        isMulti={isMulti}
        name={props.id || props.name}
        styles={customStyles}
        isSearchable={isSearchable || false}
        blurInputOnSelect
        placeholder={placeholder}
        isDisabled={disabled}
      />
      {meta.touched && !!meta.error ? (
        <div className={styles.errorMessage}>*{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomFormikSelect;
