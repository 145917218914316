import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditProjectsForm from 'components/members/EditProjectsForm';
import Preloader from 'components/shared/Preloader';
import { projectsFilterListSelector } from 'features/projectsFilterList/projectsFilterListSlice';
import { changeMemberInfo } from 'features/memberInfo/memberInfoActions';
import {
  isLoadingMemberInfoSelector,
  organizationMemberInfoSelector,
} from 'features/memberInfo/memberInfoSlice';

const EditProjects = () => {
  const currentMember = useSelector(organizationMemberInfoSelector);
  const isLoading = useSelector(isLoadingMemberInfoSelector);
  const projects = useSelector(projectsFilterListSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (memberId: number, newProjectsIds: string[]) => {
    dispatch(changeMemberInfo({ id: memberId, projects: newProjectsIds }));
  };

  return isLoading || !currentMember ? (
    <Preloader />
  ) : (
    <EditProjectsForm
      memberId={currentMember.id}
      organizationProjects={projects}
      currentMemberProjects={currentMember.projects}
      canEdit={currentMember.canEdit}
      onSubmit={handleSubmit}
    />
  );
};

export default EditProjects;
