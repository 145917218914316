import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditRolesForm from 'components/members/EditRolesForm';
import Preloader from 'components/shared/Preloader';
import { changeMemberRole } from 'features/memberInfo/memberInfoActions';
import { USER_ROLE } from 'features/user/userTypes';
import {
  isLoadingMemberInfoSelector,
  organizationMemberInfoSelector,
} from 'features/memberInfo/memberInfoSlice';

const EditRoles = () => {
  const currentMember = useSelector(organizationMemberInfoSelector);
  const isLoading = useSelector(isLoadingMemberInfoSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (memberId: number, newRole: keyof typeof USER_ROLE) => {
    dispatch(changeMemberRole({ id: memberId, role: newRole }));
  };

  return isLoading || !currentMember ? (
    <Preloader />
  ) : (
    <EditRolesForm
      memberId={currentMember.id}
      role={currentMember.role}
      onSubmit={handleSubmit}
    />
  );
};

export default EditRoles;
