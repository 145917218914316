import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { isRejectedAction } from '../sliceHelpers';
import { IMembersFilterListSliceInitialState } from './membersFilterListTypes';
import { getMembersFilterList } from './membersFilterListActions';

const initialState: IMembersFilterListSliceInitialState = {
  list: [],
  isLoading: false,
  errorMessage: '',
};

export const membersFilterListSlice = createSlice({
  name: '@@membersFilterList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMembersFilterList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMembersFilterList.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const membersFilterListSelector = createSelector(
  [(state: RootState) => state.membersFilterList.list],
  (list) => list
);

export const membersFilterIsLoadingSelector = createSelector(
  [(state: RootState) => state.membersFilterList.isLoading],
  (isLoading) => isLoading
);
