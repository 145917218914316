import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AppDispatch } from 'app/store';
import { toast } from 'react-toastify';
import { getSubscription } from 'features/subscription/subscriptionActions';
import { fetchToDosFilterList } from 'api/ToDo/toDoFilterList';
import { addToDo } from 'api/ToDo/toDoList';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import type { IFetchItemsTemplate } from '../../helpers/Types/FetchItemsTemplate'
import type { IFetchToDoParams, IToDoAddModel, IToDoListItem } from './toDoListTypes';

export const getToDosListAction = createAsyncThunk<
    IFetchItemsTemplate<IToDoListItem>,
    IFetchToDoParams | undefined,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
    >('@@toDosList/getToDosListAction', async (params, thunkApi) => {
        try {
            const res = await fetchToDosFilterList(params);
            thunkApi.dispatch(getSubscription());
            return res.data;
          } catch (err) {
            return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
          }
    });

export const getToDosListInfiniteScrollAction = createAsyncThunk<
    IFetchItemsTemplate<IToDoListItem>,
    IFetchToDoParams | undefined,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
    >('@@toDosList/getToDosListInfiniteScrollAction', async (params, thunkApi) => {
        try {
            const res = await fetchToDosFilterList(params);
            thunkApi.dispatch(getSubscription());
            return res.data;
          } catch (err) {
            return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
          }
    });

export const addToDoAction = createAsyncThunk<
    void,
    IToDoAddModel,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
    >('@@toDosList/AddToDoAction', async (model, thunkApi) => {
        try {
            const response = await addToDo(model);
            if (response.status === 200) {
              thunkApi.dispatch(getToDosListAction());
              toast.success('New ToDo Created!');
            }
          } catch (err) {
            return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
          }
    });