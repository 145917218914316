import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ForbiddenImage from 'assets/images/403ErrorForbidden.png';
import Button from 'components/shared/Button';
import styles from './Subscribe.module.scss';
import { useSelector } from 'react-redux';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';

const Subscribe: FC = () => {
  const navigate = useNavigate();
  const sub = useSelector(subscriptionInfoSelector);

  const handleSubscribe = () => {
    window.location.href = `${process.env.REACT_APP_OIDC_AUTHORITY}/subscription`;
  }

  useEffect(() => {
    if (sub.info != null && sub.info.active) {
      navigate("/");
    }
  }, [sub.info]);

  return sub.info != null ? (
    <div className={styles.root}>
      <p>
        Ooops. Looks like your organization subscription has been suspended or hasn&apos;t been activated yet. We have 15-days trial period!
      </p>
      <img src={ForbiddenImage} alt="403 Forbidden Image" />
      {sub.info!.canManage && (
        <Button variant="primary" onClick={handleSubscribe}>
          Activate Subscription
        </Button>
      )}
    </div>
  ) : null;
};

export default Subscribe;
