import client from './axiosInstance';
import {
  ChangeMemberActivityStatusParams,
  IFetchMembersParams,
} from 'features/members/membersTypes';

export const fetchOrganizationMembersList = async (
  params: IFetchMembersParams | void
) => {
  if (params) {
    const { searchValue, projectIds, membersIds, clientsIds, showDeleted, skip, take } = params;
    const showDeletedQuery = `?showDeleted=${showDeleted}`;
    const searchStringQuery =
      searchValue && searchValue.length > 0 ? `&search=${searchValue}` : '';
    const projectsIdsQuery =
      projectIds && projectIds.length > 0 ? projectIds.map(id => `&projectIds=${id}`).join('') : '';
    const clientsIdsQuery =
      clientsIds && clientsIds.length > 0 ? clientsIds.map(id => `&clientsIds=${id}`).join('') : '';
    const membersIdsQuery =
      membersIds && membersIds.length > 0 ? membersIds.map(id => `&membersIds=${id}`).join('') : '';
    const skipQuery = `&skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const queryStr = `${showDeletedQuery}${searchStringQuery}${skipQuery}${takeQuery}${projectsIdsQuery}${clientsIdsQuery}${membersIdsQuery}`;
    return await client.get(`/members${queryStr}`);
  } else {
    return await client.get(`/members`);
  }
};

export const changeMemberActivityStatus = async (
  params: ChangeMemberActivityStatusParams
) => {
  const { currentStatus, memberId } = params;
  if (currentStatus) {
    return await client.patch(`/members/${memberId}/disable`, {});
  } else {
    return await client.patch(`/members/${memberId}/enable`, {});
  }
};

export const removeMemberById = async (memberId: number) => {
  return await client.delete(`/members/${memberId}`);
};
