import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import type { RootState } from 'app/store';
import type { IToDosFilterListState } from './toDosFilterTypes';
import { getToDosFilterListAction } from './toDosFilterListActions';

const initialState: IToDosFilterListState = {
    toDosList: [],
    isLoading: false,
    errorMessage: '',
}

export const toDosFilterListSlice = createSlice({
    name: '@@toDosFilterList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getToDosFilterListAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getToDosFilterListAction.fulfilled, (state, action) => {
            state.toDosList = action.payload.list;
            state.isLoading = false;
        });
        builder.addMatcher(isFulfilledAction, (state) => {
            state.errorMessage = '';
            state.isLoading = false;
        });
        builder.addMatcher(isRejectedAction, (state, action) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        });
    },
});

export const toDosFilterListSelector = createSelector(
    [(state: RootState) => state.toDosFilterList.toDosList],
    (toDosList) => toDosList
);