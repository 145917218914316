import {
  MinutesCountInDay,
  MillisecondCountInMinute
} from "constants/timeConstanst";

export const minutesCountInDateRange = (
  startDate: string | Date,
  endDate: string | Date
): number => {
  const startTime = new Date(startDate);
  const endTime = new Date(endDate);

  startTime.setHours(0,0,0,0);
  endTime.setHours(0,0,0,0);

  const difference = endTime.getTime() - startTime.getTime();

  return Math.ceil(difference / MillisecondCountInMinute) + MinutesCountInDay;
};
