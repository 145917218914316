import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AppDispatch } from 'app/store';
import { toast } from 'react-toastify';
import { fetchToDoInfo, updateToDoInfo } from 'api/ToDo/toDoInfo';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import type { IToDoEntity, IToDoUpdateData } from './toDoInfoTypes';

export const getToDoByIdAction = createAsyncThunk<
    IToDoEntity,
    number,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
    >('@@toDo/getToDoByIdAction', async (toDoId, thunkApi) => {
        try {
            const response = await fetchToDoInfo(toDoId);
            return response.data;
        } catch (err) {
            return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
        }
    });

export const updateToDoAction = createAsyncThunk<
    IToDoEntity,
    IToDoUpdateData,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown; 
    }
    >('@@toDo/updateToDoAction', async (updateModel, thunkApi) => {
        try {
            const response = await updateToDoInfo(updateModel);
            if (response.status === 200) {
                toast.success('ToDo info successfully updated!');
            }
            return response.data;
        } catch (err) {
            return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
        }
    });