import { ISelectOption } from "components/shared/forms/DropdownSelect/DropdownSelect";
import { useFormikContext } from "formik";
import { Dispatch, SetStateAction } from "react";

interface AccrualFormObserverProps  {
  id : string,
  setId: Dispatch<SetStateAction<string>>
}

const AccrualOptionFormObserver = ({id, setId} : AccrualFormObserverProps) => {
    const { values } = useFormikContext<{accrualOption: ISelectOption}>();
    const newAccrualOptionId = values.accrualOption.value;
        
    if(newAccrualOptionId !== id){
      setId(newAccrualOptionId);
    }
  
    return null;
};

export default AccrualOptionFormObserver;