import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PhoneNumberValidationInput from 'components/shared/forms/PhoneNumberValidationInput';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import Button from 'components/shared/Button';
import {
  MaxLength100Symbols,
  MaxLength50Symbols,
} from 'constants/formsConstants';
import { IClientInfo } from 'features/clientInfo/clientInfoTypes';
import styles from './EditClientGeneralForm.module.scss';

interface EditProjectGeneralFormProps {
  projectClient: IClientInfo;
  canEdit: boolean;
  onSubmit: (data: IClientInfo) => void;
}

const EditClientGeneralForm: FC<EditProjectGeneralFormProps> = ({
  projectClient,
  canEdit,
  onSubmit,
}) => {
  const InitialValues = {
    clientName: projectClient.name || '',
    clientEmail: projectClient.email || '',
    clientPhone: projectClient.phoneNumber || '',
  };

  const validationSchema = Yup.object({
    clientName: Yup.string()
      .max(
        MaxLength50Symbols,
        `Maximum length ${MaxLength50Symbols} characters`
      )
      .required('Required field'),
    clientEmail: Yup.string()
      .email('Invalid email address')
      .max(
        MaxLength100Symbols,
        `*Maximum length ${MaxLength100Symbols} characters`
      )
      .required('Required field'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            id: projectClient.id,
            name: values.clientName,
            email: values.clientEmail,
            phoneNumber:
              values.clientPhone && values.clientPhone.length > 0
                ? values.clientPhone
                : null,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Client name"
              name="clientName"
              type="text"
              placeholder="Enter a client name..."
              disabled={!canEdit}
            />

            <ValidationTextInput
              label="Email"
              name="clientEmail"
              type="text"
              placeholder="Enter a client email..."
              disabled={!canEdit}
            />

            <PhoneNumberValidationInput
              label="Phone number"
              name="clientPhone"
              disabled={!canEdit}
            />

            {canEdit && (
              <div className={styles.submitWrapper}>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting}
                  preloader={formik.isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditClientGeneralForm;
