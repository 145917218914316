import client from './axiosInstance';

export const fetchSubscriptionInfo = async () => {
    return await client.get(`/subscription`);
};

export const cancelSub = async () => {
    return await client.post(`/subscription/cancel`);
};

export const resumeSub = async () => {
    return await client.post(`/subscription/resume`);
};
