import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength40Symbols } from 'constants/formsConstants';
import { IProjectInfoUpdatingData } from 'features/projectInfo/projectInfoTypes';
import { IClientShortInfo } from 'features/clientInfo/clientInfoTypes';
import styles from './EditProjectGeneralForm.module.scss';

interface EditProjectGeneralFormProps {
  projectId: number;
  projectName: string;
  projectClient: IClientShortInfo;
  organizationClients: IClientShortInfo[];
  canEdit: boolean;
  onSubmit: (data: IProjectInfoUpdatingData) => void;
}

const EditProjectGeneralForm: FC<EditProjectGeneralFormProps> = ({
  projectId,
  projectName,
  projectClient,
  organizationClients,
  canEdit,
  onSubmit,
}) => {
  const InitialValues = {
    projectName: projectName || '',
    clientId:
      projectClient && projectClient.id
        ? { label: projectClient.name, value: projectClient.id }
        : null,
  };

  const clientsOptions = organizationClients.map((c) => ({
    value: String(c.id),
    label: c.name,
  }));

  const validationSchema = Yup.object({
    projectName: Yup.string()
      .max(
        MaxLength40Symbols,
        `*Maximum length ${MaxLength40Symbols} characters`
      )
      .required('Project name is required field'),
    client: Yup.string(),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            id: projectId,
            name: values.projectName,
            clientId: values.clientId?.value,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Project name"
              name="projectName"
              type="text"
              placeholder="Enter a project name..."
              disabled={!canEdit}
            />

            <CustomFormikSelect
              label="Client"
              name="clientId"
              options={clientsOptions}
              disabled={!canEdit}
            />

            {canEdit && (
              <div className={styles.submitWrapper}>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting}
                  preloader={formik.isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProjectGeneralForm;
