import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ISubscriptionInfo, ISubscriptionSliceInitialState } from './subscriptionTypes';
import { getSubscription } from './subscriptionActions';

const initialState: ISubscriptionSliceInitialState = {
  info: null,
};

export const subscriptionSlice = createSlice({
  name: '@@subscription',
  initialState,
  reducers: {
    setSubscription: (
      state,
      action: PayloadAction<ISubscriptionInfo>
    ) => {
      state.info = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.info = action.payload;
    });
  },
});

export const {
  setSubscription
} = subscriptionSlice.actions;

export const subscriptionInfoSelector = createSelector(
  [(state: RootState) => state.subscription],
  (sub) => sub
);