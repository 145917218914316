import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  IHolidayInfo,
  IHolidayInfoSliceInitialState,
} from './holidayInfoTypes';
import { changeHolidayInfo, getHolidayInfo } from './holidayInfoActions';
import { isRejectedAction } from '../sliceHelpers';
import { IMemberShortInfo } from '../memberInfo/memberInfoTypes';

const initialState: IHolidayInfoSliceInitialState = {
  info: {
    name: '',
    newMembersAutoAdding: false,
    occursAnnually: false,
    paidHours: 0,
    date: '',
    members: [] as IMemberShortInfo[],
  } as IHolidayInfo,
  isLoading: false,
};

export const holidayInfoSlice = createSlice({
  name: '@@holidayInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHolidayInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeHolidayInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHolidayInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addCase(changeHolidayInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const holidayInfoSelector = createSelector(
  [(state: RootState) => state.holidayInfo.info],
  (holidayInfo) => holidayInfo
);

export const isLoadingHolidayInfoSelector = createSelector(
  [(state: RootState) => state.holidayInfo.isLoading],
  (isLoading) => isLoading
);
