import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditAboutForm from 'components/members/EditAboutForm';
import Preloader from 'components/shared/Preloader';
import { changeMemberInfo } from 'features/memberInfo/memberInfoActions';
import {
  isLoadingMemberInfoSelector,
  organizationMemberInfoSelector,
} from 'features/memberInfo/memberInfoSlice';

const EditAbout = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isLoadingMemberInfoSelector);
  const currentMember = useSelector(organizationMemberInfoSelector);

  const handleSubmit = (memberId: number, jobTitle: string) => {
    dispatch(
      changeMemberInfo({
        id: memberId,
        jobTitle,
      })
    );
  };

  return isLoading || !currentMember ? (
    <Preloader />
  ) : (
    <EditAboutForm
      memberId={currentMember.id}
      currentJobTitle={currentMember.jobTitle}
      canEdit={currentMember.canEdit}
      onSubmit={handleSubmit}
    />
  );
};

export default EditAbout;
