import { setTimeInDate } from './setTimeInDate';

export const setTimeInStringFormatIntoDate = (
    date: Date | string,
    time: string
  ): Date => {
    const timeCopy = new Date(time);
    
    return setTimeInDate(date, timeCopy.getHours(), timeCopy.getMinutes());
  };
  