import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { TimeOffReportsSliceInitialState } from './timeOffReportsTypes';
import { isRejectedAction } from '../sliceHelpers';
import { getTimeOffReports } from './timeOffReportsActions';

const initialState: TimeOffReportsSliceInitialState = {
  groupsTableHeaders: [],
  groups: [],
  summary: [],
  isLoading: false,
  isReportDownloading: false,
  errorMessage: '',
};

export const timeOffReportsSlice = createSlice({
  name: '@@timeOffReport',
  initialState,
  reducers: {
    setIsReportDownloadingState: (state, action: PayloadAction<boolean>) => {
      state.isReportDownloading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeOffReports.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTimeOffReports.fulfilled, (state, action) => {
      state.groupsTableHeaders = action.payload.groupsTableHeaders;
      state.groups = action.payload.groups;
      state.summary = action.payload.summary;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setIsReportDownloadingState } = timeOffReportsSlice.actions;

export const reportsGroupsListTimeOffSelector = createSelector(
  [(state: RootState) => state.timeOffReports.groups],
  (groups) => groups
);

export const groupsTableHeadersTimeOffSelector = createSelector(
  [(state: RootState) => state.timeOffReports.groupsTableHeaders],
  (headers) => headers
);

export const summaryTimeOffSelector = createSelector(
  [(state: RootState) => state.timeOffReports.summary],
  (summary) => summary
);

export const isLoadingTimeOffSelector = createSelector(
  [(state: RootState) => state.timeOffReports.isLoading],
  (isLoading) => isLoading
);

export const isReportDownloadingTimeOffSelector = createSelector(
  [(state: RootState) => state.timeOffReports.isReportDownloading],
  (isReportDownloading) => isReportDownloading
);
