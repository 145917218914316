import { FC } from 'react';
import { useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import Button from 'components/shared/Button';
import styles from './Subscription.module.scss';
import { cancelSubscription, resumeSubscription } from 'features/subscription/subscriptionActions';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { IoDocumentAttachOutline, IoMailOutline, IoPersonOutline, IoTimeOutline } from 'react-icons/io5';
import { format } from 'date-fns';

const Subscription: FC = () => {
  const dispatch = useAppDispatch();
  const sub = useSelector(subscriptionInfoSelector);

  const handleDeactivate = () => {
    dispatch(cancelSubscription());
  };

  const handleSubscribe = () => {
    window.location.href = `${process.env.REACT_APP_OIDC_AUTHORITY}/subscription`;
  }

  return (
    <>
      <>
        {sub.info != null ? (
          <div className={styles.wrapper}>
            
            <div className={styles.profileInfo}>
              <div className={styles.fullName}>Your Subscription Info:</div>
              <div className={styles.email}>
                <IoPersonOutline /> <div>You have {sub.info.subscribersCount} active users in your organization.</div>
              </div>
              <div className={styles.email}>
                <IoDocumentAttachOutline /> <div>We charge 1$ per active user when you are subscribed to All Included. Your monthly subscription is ${sub.info.subscribersCount}.00</div>
              </div>
              {sub.info.nextPaymentDate && (
                <div className={styles.timeZone}>
                  <IoTimeOutline />
                  <div>Your next Payment Date is {format(new Date(sub.info.nextPaymentDate), 'MMM dd, yyyy')}</div>
                </div>
              )}
            </div>

            <div className={styles.submitWrapper}>
              {sub.info.active ? (
                <Button
                  type="submit"
                  variant="delete"
                  onClick={handleDeactivate}
                >
                  Deactivate Subscription
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="primary"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </Button>
              )}

            </div>
          </div>
        ) : null}
      </>
    </>
  );
};

export default Subscription;
