import type { FC } from 'react';
import { Form, Formik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import CustomFormikDatePicker from 'components/shared/forms/datePickers/CustomFormikDatePicker';
import CustomFormikCheckbox from 'components/shared/forms/CustomFormikCheckbox';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import Button from 'components/shared/Button';
import { MaxLength50Symbols } from 'constants/formsConstants';
import {
  IHolidayInfo,
  IHolidayInfoUpdatingData,
} from 'features/holidayInfo/holidayInfoTypes';
import styles from './EditHolidayGeneralForm.module.scss';

interface EditHolidayGeneralFormProps {
  holiday: IHolidayInfo;
  onSubmit: (data: IHolidayInfoUpdatingData) => void;
}

const EditHolidayGeneralForm: FC<EditHolidayGeneralFormProps> = ({
  holiday,
  onSubmit,
}) => {
  const InitialValues = {
    holidayName: holiday.name,
    holidayDate: holiday.date,
    paidHours: holiday.paidHours,
    newMembersAutoAdding: holiday.newMembersAutoAdding,
    occursAnnually: holiday.newMembersAutoAdding
  };

  const validationSchema = Yup.object({
    holidayName: Yup.string()
      .max(
        MaxLength50Symbols,
        `Maximum length ${MaxLength50Symbols} characters`
      )
      .required('Holiday name is required field'),
    paidHours: Yup.number()
      .required('Paid hours is required field')
      .max(24, 'Must be no more than 24 hours')
      .typeError('Paid hours must be a `number`'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            id: holiday.id,
            name: values.holidayName,
            date: format(new Date(values.holidayDate), 'yyyy-MM-dd'),
            paidHours: Number(values.paidHours),
            newMembersAutoAdding: values.newMembersAutoAdding,
            occursAnnually: values.occursAnnually
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Holiday name"
              name="holidayName"
              type="text"
            />

            <CustomFormikDatePicker label="Holiday date" name="holidayDate" />

            <div style={{ width: '150px' }}>
              <ValidationTextInput
                label="Paid hours"
                name="paidHours"
                type="text"
              />
            </div>

            <CustomFormikCheckbox
              label="Add new members to this holiday"
              name="newMembersAutoAdding"
            />

            <CustomFormikCheckbox
              label="Occurs annually"
              name="occursAnnually"
            />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting || !formik.isValid}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditHolidayGeneralForm;
