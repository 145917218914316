import { IMemberShortInfo } from "features/memberInfo/memberInfoTypes";
import { IProjectShortInfo } from "features/projectInfo/projectInfoTypes";
import type { IBaseToDoModel } from "features/toDosFilterList/toDosFilterTypes";
import type { IToDoAddModel } from "features/toDosList/toDoListTypes";

export interface IToDoInfoState {
    toDo : IToDoEntity,
    isLoading: boolean;
    errorMessage: string | unknown;
}

export enum ToDoStatus {
    Open = 1,
    Done = 2,
}

export interface IToDoEntity extends IBaseToDoModel {
    project: IProjectShortInfo;
    assignee: IMemberShortInfo | null;
    status: ToDoStatus,
    canEdit: false;
    canAssignToAccount: false;
    canChangeProject: false;
}

export type IToDoUpdateData = Omit<IToDoAddModel, 'projectId'> & {
    id: number;
    status: ToDoStatus;
}