import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from 'app/store';
import { FilteredMembersList, IFetchMembersFilterPanelParams, type IMembersFilterPanelState } from './membersFilterPanelListTypes';
import { getFilteredPanelMembers } from './membersFilterPanelListActions';
import { isFulfilledAction, isRejectedAction } from 'features/sliceHelpers';


const initialState: IMembersFilterPanelState = {
  filteredMembers: {},
  selectedMembersForCircles: {},
  totalCount: 0,
  filter: {
    showDeleted: false,
    searchValue: '',
    projectIds: [],
    membersIds: [],
    clientsIds: [],
    isUpdateCircles: false,
    skip: 0,
    take: 20,
  },
  isLoading: false,
  errorMessage: '',
};

export const membersFilterPanelSlice = createSlice({
  name: '@@membersFilterPanel',
  initialState,
  reducers: {
    setFilteredMembersPageFilter: (
      state,
      action: PayloadAction<IFetchMembersFilterPanelParams>
    ) => {
      state.filter = action.payload;
    },
    setFilteredMembers(state, action: PayloadAction<FilteredMembersList>) {
      state.filteredMembers = action.payload;
    },
    setSelectedMembersForCircle(state, action: PayloadAction<FilteredMembersList>) {
      state.selectedMembersForCircles = action.payload;
    },
    clearFilteredMembers(state) {
      state.filteredMembers = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilteredPanelMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFilteredPanelMembers.fulfilled, (state, action) => {
      const initialFiltredMembersState = Object.fromEntries(
        action.payload.list.map(member => [member.id.toString(), { member, isChecked: true }])
      );

      if(state.filter.isUpdateCircles){
        state.selectedMembersForCircles = initialFiltredMembersState;
        state.filter.isUpdateCircles = false;
      }
      state.filteredMembers = initialFiltredMembersState;
      state.totalCount = action.payload.count;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { 
  setFilteredMembersPageFilter, 
  setFilteredMembers, 
  setSelectedMembersForCircle,
  clearFilteredMembers 
} = membersFilterPanelSlice.actions;

export const listMembersFilterPanelSelector = createSelector(
  [(state: RootState) => state.membersFilterPanel.filteredMembers],
  (list) => list
);
export const listSelectedMembersForCirclesFilterPanelSelector = createSelector(
  [(state: RootState) => state.membersFilterPanel.selectedMembersForCircles],
  (list) => list
);
export const filterMembersFilterPanelSelector = createSelector(
  [(state: RootState) => state.membersFilterPanel.filter],
  (filter) => filter
);

export const isLoadingMembersFilterPanelSelector = createSelector(
  [(state: RootState) => state.membersFilterPanel.isLoading],
  (isLoading) => isLoading
);
