import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import Button from 'components/shared/Button';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { IProjectShortInfo } from 'features/projectInfo/projectInfoTypes';
import styles from './EditProjectsForm.module.scss';

interface EditProjectsFormProps {
  memberId: number;
  organizationProjects: IProjectShortInfo[];
  currentMemberProjects: IProjectShortInfo[];
  canEdit: boolean;
  onSubmit: (memberId: number, newProjectsIds: string[]) => void;
}

const EditProjectsForm: FC<EditProjectsFormProps> = ({
  memberId,
  organizationProjects,
  currentMemberProjects,
  canEdit,
  onSubmit,
}) => {
  const InitialValues = {
    projects:
      currentMemberProjects && currentMemberProjects.length > 0
        ? currentMemberProjects.map((p) => ({
            value: String(p.id),
            label: p.name,
          }))
        : null,
  };

  const projectsOptions = organizationProjects.map((p) => ({
    value: String(p.id),
    label: p.name,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(
            memberId,
            values.projects && values.projects.length > 0
              ? values.projects.map((p) => p.value)
              : []
          );
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <CustomFormikSelect
              label={'Projects'}
              name="projects"
              options={projectsOptions}
              isMulti
              disabled={!canEdit}
            />

            {canEdit && (
              <div className={styles.submitWrapper}>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting}
                  preloader={formik.isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProjectsForm;
