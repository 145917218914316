import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { setSubscription } from './subscriptionSlice';
import { fetchSubscriptionInfo, cancelSub, resumeSub } from 'api/subscription';
import { ISubscriptionInfo } from './subscriptionTypes';

export const getSubscription = createAsyncThunk<
  ISubscriptionInfo,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@subscription/getSubscription', async (params, thunkApi) => {
  try {
    const res = await fetchSubscriptionInfo();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const cancelSubscription = createAsyncThunk<
  void,
  void
>('@@subscription/cancelSubscription', async (params, thunkApi) => {
  try {
    const res = await cancelSub();
    if (res.status === 200) {
      toast.success('Subscription has been canceled.');
      thunkApi.dispatch(setSubscription(res.data));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const resumeSubscription = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@subscription/resumeSubscription', async (params, thunkApi) => {
  try {
    const res = await resumeSub();
    if (res.status === 200) {
      toast.success('Congrats! Your subscription has been activated.');
      thunkApi.dispatch(setSubscription(res.data));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
