import * as Yup from 'yup';
import { minutesCountInDateRange } from 'helpers/minutesCountInDateRange';
import { minutesCountInDateRangeWithTime } from 'helpers/minutesCountInDateRangeWithTime';
import { minutesCountInTime } from 'helpers/minutesCountInTime';
import { hoursColonMinutesFormat } from 'helpers/regularExpressions';

export const timeRequestedValidation = {
    timeRequested: Yup.string()
        .matches(hoursColonMinutesFormat, 'HH:MM time format must be specifying')
        .when(['startDate', 'endDate'], (startDate, endDate) => {
            return Yup.string().when('allDay', {
                is: false,
                then: Yup.string().when(['startTime', 'endTime'], (startTime, endTime) => {
                    return Yup.string().test('inDatePeriodNotAllDay',
                        `Requested time off not included in the selected period`, (value) => {
                            if (!value) {
                                return true;
                            }

                            const requestedTimeOffMinutesCount = minutesCountInTime(value);
                            const minutesCountInSelectedDateRange = minutesCountInDateRangeWithTime(startDate, endDate, startTime, endTime);

                            return minutesCountInSelectedDateRange >= requestedTimeOffMinutesCount &&
                                requestedTimeOffMinutesCount > 0;
                        })
                    }),
                otherwise: Yup.string().test('inDatePeriodAllDay',
                    `Requested time off not included in the selected period`, (value) => {
                        if (!value) {
                            return true;
                        }

                        const requestedTimeOffMinutesCount = minutesCountInTime(value);
                        const minutesCountInSelectedDateRange = minutesCountInDateRange(startDate, endDate);

                        return minutesCountInSelectedDateRange >= requestedTimeOffMinutesCount &&
                            requestedTimeOffMinutesCount > 0;
                    }),
            });
        })
}