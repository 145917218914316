import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProjectManagementIcon } from 'assets/icons/Menu/ProjectManagementIcon';
import { TeamplayLogoIcon } from 'assets/icons/Menu/TeamplayLogoIcon';
import { TimesheetsIcon } from 'assets/icons/Menu/TimesheetsIcon';
import { TimeOffIcon } from 'assets/icons/Menu/TimeOffIcon';
import { ReportsIcon } from 'assets/icons/Menu/ReportsIcon';
import { PeopleIcon } from 'assets/icons/Menu/PeopleIcon';
import { MenuIcon } from 'assets/icons/Menu/MenuIcon';
import SidebarSubMenuLinks from 'components/appLayout/Sidebar/SidebarSubMenuLinks';
import { ISubMenuLink } from 'components/appLayout/Sidebar/SidebarSubMenuLinks/SidebarSubMenuLinks';
import { permissionsUserSelector } from 'features/user/userSlice';
import SidebarLink from './SidebarLink';
import styles from './Sidebar.module.scss';
import { OrgIcon } from 'assets/icons/Menu/OrgIcon';

interface SidebarProps {
  isOnMobileOpen: boolean;
  closeMobileSidebar: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isOnMobileOpen, closeMobileSidebar }) => {
  const location = useLocation();
  const permissions = useSelector(permissionsUserSelector);

  const projectsActiveTitle = /^\/projects|clients/.test(location.pathname);
  const peopleActiveTitle = /^\/members/.test(location.pathname);
  const reportsActiveTitle = /^\/reports/.test(location.pathname);
  const timeOffActiveTitle = /^\/time-off/.test(location.pathname);
  const organizationActiveTitle = /^\/organization/.test(location.pathname);

  const isPeopleSectionAvailable = permissions?.some(
    (el) => el.name === 'ViewPeopleSectionNavLink'
  );
  const isClientsNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewProjectManagementClientsSubSectionNavLink'
  );
  const isPoliciesNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewTimeOffPoliciesSubSectionNavLink'
  );
  const isHolidaysNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewTimeOffHolidaysSubSectionNavLink'
  );
  const isTimeOffRequestsNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewTimeOffRequestsSubSectionNavLink'
  );
  const isAmountsOwedReportAvailable = permissions?.some(
    (el) => el.name === 'View Amounts owed'
  );
  const isTimeAndActivityReportAvailable = permissions?.some(
    (el) => el.name === 'View Time and activity'
  );
  const isMyOrganizationNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewMyOrganizationPage'
  );
  const isAllReportsNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewReportsAllSubSectionNavLink'
  );
  const isAmountsOwedReportNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewReportsAmountsOwedSubSectionNavLink'
  );

  const projectsSubMenu: ISubMenuLink[] = [
    {
      title: 'ToDo / Teams',
      path: `todo`,
      isAvailable: true,
    },
    {
      title: 'Projects / Teams',
      path: `projects`,
      isAvailable: true,
    },
    {
      title: 'Clients',
      path: `clients`,
      isAvailable: !!isClientsNavLinkAvailable,
    },
  ];
  const peopleSubMenu: ISubMenuLink[] = [
    {
      title: 'Members',
      path: `members`,
      isAvailable: true,
    },
  ];
  const reportsSubMenu: ISubMenuLink[] = [
    {
      title: 'Time & activity',
      path: isTimeAndActivityReportAvailable
        ? 'reports/time-and-activity'
        : 'reports/time-and-activity/my',
      isAvailable: true,
    },
    {
      title: 'Amounts owed',
      path: isAmountsOwedReportAvailable
        ? 'reports/amounts-owed'
        : 'reports/amounts-owed/my',
      isAvailable: !!isAmountsOwedReportNavLinkAvailable,
    },
    {
      title: 'Time offs',
      path: isAmountsOwedReportAvailable
        ? 'reports/time-off'
        : 'reports/time-off/my',
      isAvailable: !!isAmountsOwedReportNavLinkAvailable,
    },
    {
      title: 'All reports',
      path: 'reports/all-reports',
      isAvailable: !!isAllReportsNavLinkAvailable,
    },
  ];

  const timeOffSubMenu: ISubMenuLink[] = [
    {
      title: 'Policies',
      path: `time-off/policies`,
      isAvailable: !!isPoliciesNavLinkAvailable,
    },
    {
      title: 'Holidays',
      path: `time-off/holidays`,
      isAvailable: !!isHolidaysNavLinkAvailable,
    },
    {
      title: 'Requests',
      path: `time-off/requests`,
      isAvailable: !!isTimeOffRequestsNavLinkAvailable,
    },
  ];

  const organizationSubMenu: ISubMenuLink[] = [
    {
      title: 'My organization',
      path: `organization`,
      isAvailable: !!isMyOrganizationNavLinkAvailable,
    },
    {
      title: 'Subscription',
      path: `subscription`,
      isAvailable: !!isMyOrganizationNavLinkAvailable,
    },
  ];

  return (
    <>
      <aside
        className={`${styles.aside} ${isOnMobileOpen ? styles.showMobileSidebar : ''
          }`}
      >
        <div className={styles.asideHeader}>
          <Link to="/" aria-label="Main page link" className={styles.logo}>
            <TeamplayLogoIcon />
          </Link>
          <button
            aria-label="Close sidebar menu"
            className={styles.menu}
            onClick={closeMobileSidebar}
          >
            <MenuIcon />
          </button>
        </div>
        <div className={`${styles.asideBody}`}>
          <ul>
            <SidebarLink
              path={`time-entries`}
              title="Timesheets"
              icon={<TimesheetsIcon />}
            />
            <SidebarSubMenuLinks
              menuTitle="Project management"
              icon={<ProjectManagementIcon />}
              subMenuItemsData={projectsSubMenu}
              activeTitle={projectsActiveTitle}
            />
            {!!isPeopleSectionAvailable && (
              <SidebarSubMenuLinks
                menuTitle="People"
                icon={<PeopleIcon />}
                subMenuItemsData={peopleSubMenu}
                activeTitle={peopleActiveTitle}
              />
            )}
            <SidebarLink
              path={`team-overview`}
              title="Team overview"
              icon={<OrgIcon />}
            />
            <SidebarSubMenuLinks
              menuTitle="Reports"
              icon={<ReportsIcon />}
              subMenuItemsData={reportsSubMenu}
              activeTitle={reportsActiveTitle}
            />
            <SidebarSubMenuLinks
              menuTitle="Time off"
              icon={<TimeOffIcon />}
              subMenuItemsData={timeOffSubMenu}
              activeTitle={timeOffActiveTitle}
            />
            {!!isMyOrganizationNavLinkAvailable && (
              <SidebarSubMenuLinks
                menuTitle="Organization"
                icon={<OrgIcon />}
                subMenuItemsData={organizationSubMenu}
                activeTitle={organizationActiveTitle}
              />
            )}
          </ul>
        </div>
      </aside>
      <div
        className={isOnMobileOpen ? styles.backDropOn : styles.backDropOff}
        onClick={closeMobileSidebar}
      />
    </>
  );
};

export default Sidebar;
